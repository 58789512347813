import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { axiosInstance } from '../../lib';

/**
 * @description Component sends Reset-Password link to user's email.
 * @output Renders one input field that takes in email,
 * @objective saves email to state, and calls back-end API with data(email) for user Forgot-Password that sends an automated reset-link to the user.
 */

export function ForgotPassword() {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [validated, setValidated] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity())
      axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/password/forgot?Email=${email}`
      );
    setValidated(true);
  };

  return (
    <div
      style={{
        margin: '50px auto 50px',
        maxWidth: '320px',
        backgroundColor: '#E7E7E7',
        padding: '35px 30px',
        borderRadius: '20px',
      }}
    >
      <Row className='mb-3 ml-1'>
        <NavLink to='/'>{t('button.back')}</NavLink>
      </Row>
      <h5>{t('auth.retrieveForgottenPassword')}</h5>
      <br />
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group controlId='email'>
          <Form.Label>{t('common.email')}</Form.Label>
          <Form.Control
            type='email'
            required
            placeholder='Enter Username or Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Form.Control.Feedback type='invalid'>
            Email is required.
          </Form.Control.Feedback>
          <Form.Text className='text-muted'>
            {t('auth.forgotPasswordText')}
          </Form.Text>
        </Form.Group>
        <Button variant='dark' type='submit'>
          Submit
        </Button>
      </Form>
    </div>
  );
}
