import React, { useContext, useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import '../assets/css/style.css';
import { LoginContext } from '../auth';
import { ServiceEndPoint } from '../constants';
import { CommonDataService } from '../services';
import toast, { Toaster } from 'react-hot-toast';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { AppDataTable } from '../common/components/table';
import { ActionType, FORM } from '../constants/enum';
import renderif from '../constants/renderif';

import {ApproveRequest} from './approve-request';

interface ArtwokProps {
  requestId: any;
  bNew: boolean;
  onHide: () => void;
  onSuccess: () => void;
}

export const ArtWorkRequest = (props: ArtwokProps) => {
  const { state } = useContext(LoginContext);
  const ref = useRef<any>(null);
  const { t } = useTranslation();
  const [payload, setPayLoad] = useState(JSON.stringify({ requestId: props.requestId }));
  const [displayBasic, setDisplayBasic] = useState(false);

  const [cols, setCols] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>();
  const [selectedStock, setSelectedStock] = useState('');
  const [actionType, setActionType] = useState(1);
  const [prefLocation, setPrefLocation] = useState(0);

  const commonDataService = new CommonDataService();
  const saveChanges = () => {
   
  }

  useEffect(() => {
    let _cols: any[] = [];
    _cols.push({ id: 1, formId: 1, colName: 'image', headerText: 'image', colWidth: 70, colAlignment: 1, colFormat: '', colSeqNo: 1, colIsVisible: 1, colTranslate: 0, colType: 4});
    _cols.push({ id: 2, formId: 1, colName: 'title', headerText: 'title', colWidth: 0, colAlignment: 1, colFormat: '', colSeqNo: 2, colIsVisible: 1, colTranslate: 0, colType: 1});
    _cols.push({ id: 3, formId: 1, colName: 'location', headerText: 'location', colWidth: 250, colAlignment: 1, colFormat: '', colSeqNo: 3, colIsVisible: 1, colTranslate: 0, colType: 1});
    _cols.push({ id: 4, formId: 1, colName: 'status', headerText: 'status', colWidth: 150, colAlignment: 1, colFormat: '', colSeqNo: 4, colIsVisible: 1, colTranslate: 0, colType: 1})
    setCols(_cols);
}, []); // eslint-disable-line react-hooks/exhaustive-deps


  const onApprove = (type: number) => {
    
    if(ref.current.getSelectedRows().length > 0){
        setSelectedStock(ref.current.getSelectedRows().map((item: any)=> item.id).join(","));
        if(ref.current.getSelectedRows().length == 1){
          setPrefLocation(ref.current.getSelectedRows()[0].pickupLocation);
        }
        setActionType(type);
        setDisplayBasic(true);
    }
    else{
      confirmAlert({
        title: t('common.message'),
        message: t('alert.select_at_least_one_record'),
        buttons: [
          {
            label: t('button.ok'),
            onClick: () => {}
          }
        ]
      });
    }
  }

  const onHide = () => {
    setDisplayBasic(false);
  }

  const onSuccess = () => {
    setDisplayBasic(false);
    ref.current.updateMetaData();
  }

  const renderDate = (rowData: any, column: any) => {
    let col = cols.find(a => a.colName === column.field);
    return <span>{Moment(rowData[column.field]).format(col.colFormat)}</span>;
}

const renderImage = (rowData: any, column: any) => {
    if(rowData[column.field] !== ''){
        return <img src={ `${process.env.REACT_APP_API_URL}/api${rowData[column.field]}`} style={{width: 40, height: 40}}/>
    }
    return '';
}


const dynamicColumns = cols.map((col, i) => {
    if (col.colType === 2) {
        return <Column key={i} field={col.colName} header={t("common." + col.headerText)} filter sortable={true} headerStyle={{ width: col.colWidth === 0 ? '100%' : col.colWidth.toString() + 'px' }} />;
    }
    else if (col.colType === 3) {
        return <Column key={i} field={col.colName} body={renderDate} header={t("common." + col.headerText)} filter sortable={true} headerStyle={{ width: col.colWidth === 0 ? '100%' : col.colWidth.toString() + 'px' }} />;
    }
    else if(col.colType === 4){
        return <Column key={i} field={col.colName} body={renderImage} header={t("common." + col.headerText)} filter sortable={true} headerStyle={{ width: col.colWidth === 0 ? '100%' : col.colWidth.toString() + 'px' }} />;
    }
   
    return <Column key={i} field={col.colName} header={t("common." + col.headerText)} filter sortable={true} headerStyle={{ width: col.colWidth === 0 ? '100%' : col.colWidth.toString() + 'px' }} />;
});

const removeRows = () =>{

}

const onDelete = () => {
  if(selectedRows && selectedRows.length > 0){
    confirmDialog({
      message: t('alert.delete_message'),
      header: t('alert.delete_confirmation'),
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      acceptLabel: t('button.yes'),
      rejectLabel: t('button.no'),
      accept: ()=>{
        let _rows = [...rows];  
        selectedRows.forEach(item=>{
            let index = _rows.indexOf(item);
            if(index >=0){
              _rows.splice(index, 1);
            }

          });
          setSelectedRows([]);
          setRows(_rows);
      }
  });
  }
  else{
    confirmAlert({
      title: t('common.message'),
      message: t('alert.select_at_least_one_record'),
      buttons: [
        {
          label: t('button.ok'),
          onClick: () => {}
        }
      ]
    });
  }
}

const onAction = (actionType: ActionType, selectedRows: any[]) => {
  switch (actionType) {
      case ActionType.ADD:
          
          break;
      case ActionType.EDIT:
         
          break;
      case ActionType.DELETE:
         
          break;
      default:
          break;
  }

}


  return (
    <Container className='mt-5' >
      
      <Row style={{ marginBottom: 5 }}>
                <Col lg="3">
                   
                </Col>
               
                {renderif(!props.bNew)(
                <Col lg="9" style={{ textAlign: 'right' }}>
                  <Button label={t('button.approve')} icon="pi pi-check" className="p-button-sm mr-1" onClick={() => onApprove(1)} />
                    <Button label={t('button.reject')} icon="pi pi-times" className="p-button-sm p-button-danger mr-1" onClick={() => onApprove(2)} />
                <Button label={t('button.back')} icon="pi pi-arrow-left" className="p-button-sm" onClick={() => props.onHide()} />
                </Col>
                )}
            </Row>
            {renderif(props.bNew)(
            <div className="card">
                <DataTable 
                    dataKey="stockId" 
                    value={rows} 
                    selection={selectedRows} 
                    onSelectionChange={e => setSelectedRows(e.value)} 
                    scrollHeight="440px"
                    scrollable
                    >
                    <Column selectionMode="multiple" headerStyle={{ width: '30px' }}></Column>
                    {dynamicColumns}

                </DataTable>

            </div>
            )}
            {renderif(!props.bNew)(
              <div>
               <AppDataTable
                ref={ref}
               title={t('menu.artwork-request')}
               formId={FORM.ART_WORK_REQUEST_DETAIL}
               endpointUrl={ServiceEndPoint.ART_WORK.FETCH_USER_REQUEST_DETAIL}
               payload={payload}
               bAllowActions={false}
               onAction={onAction} />
               <Dialog header={t('common.approve_request')} visible={displayBasic} style={{ width: '30vw' }} onHide={() => onHide()}>
                  <ApproveRequest 
                      onHide={onHide} 
                      onSuccess={onSuccess} 
                      requestId={props.requestId} 
                      type={actionType} 
                      stockId={selectedStock} 
                      preferedLocation = {prefLocation}
                      ></ApproveRequest>
                </Dialog>
                </div>
            )}

      <Toaster />
    </Container>
  );
};
