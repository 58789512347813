export enum ActionType  {
    ADD = 1,
    EDIT = 2,
    DELETE = 3
}

export enum FORM  {
    PRODUCT_GROUP = 1,
    STATUS = 2,
    PRODUCT_CATEGORY = 3,
    ARTIST = 4,
    TECHNIQUE = 5,
    MATERIAL = 6,
    AREA = 7,
    AREA_ROOM = 8,
    ART_WORK = 9,
    ART_WORK_REQUEST = 10,
    ART_WORK_REQUEST_DETAIL = 11,
    ART_WORK_REUEST = 12,
    ART_WORK_RECEIVE = 13,
    PREFERED_LOCATION = 14
}