import React, { useContext, useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LoginContext } from '../auth';
import { AppDataTable } from '../common/components/table';
import { ServiceEndPoint } from '../constants';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { confirmDialog } from 'primereact/confirmdialog';
import '../assets/css/style.css';
import { ActionType, FORM } from '../constants/enum';
import toast, { Toaster } from 'react-hot-toast';
import { CommonDataService } from '../services';
import renderif from '../constants/renderif';
import { Dropdown } from 'primereact/dropdown';

export const ArtWorkReport = () => {
  const { state } = useContext(LoginContext);
  const ref = useRef<any>(null);
  const { t } = useTranslation();
  const [payload, setPayLoad] = useState(JSON.stringify({ orgId: state.orgID, areaId: '', roomId: '' }));
  const [displayBasic, setDisplayBasic] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});
  const [isForm, setIsForm] = useState(false);
  const [area, setArea] = useState(0);
  const [room, setRoom] = useState(0);
  const [areaList, setAreaList] = useState<any[]>([]);
  const [roomList, setRoomList] = useState<any[]>([]);

  const commonDataService = new CommonDataService();
  const onAction = (actionType: ActionType, selectedRows: any[]) => { }



  const exportCSV = (selectionOnly: any) => {
    ref.current.exportCSV(selectedObject);
  }

  const exportExcel = () => {
    ref.current.exportExcel();
  }

  const exportPdf = () => {
    ref.current.exportPdf();
  }

  useEffect(() => {
    getAreaList();

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onAreaChange = (e: any) => {
    setArea(e.value);
    getRoomList(e.value);
  }
  const getAreaList = () => {
    commonDataService.executeApiCall(ServiceEndPoint.AREA.FETCH, { orgId: state.orgID }).then(res => {
      if (res.status) {
        setAreaList([]);
        let rData: any[] = [];
        rData.push({id:0, name:'All'})
        res.data.forEach((item: any) => {
          if(item.name !==''){
          rData.push(item);
          }
        });
        setAreaList(rData);
      }
    });
  }
  const onRoomChange = (e: any) => {
    setRoom(e.value);
  }
  const getRoomList = (areaId: number) => {
    commonDataService.executeApiCall(ServiceEndPoint.AREA_ROOM.FETCH, { orgId: state.orgID }).then(res => {
      if (res.status) {
        setRoomList([]);
        let rData: any[] = [];
        rData.push({id:0, name:'All'})
        res.data.forEach((item: any) => {
          if (item.areaId === areaId && item.name!=='') {
            rData.push(item);
          }
        });
        setRoomList(rData);
      }
    });
  }
  
  const search = ()=>{
    debugger;
    //setPayLoad(JSON.stringify({ orgId: state.orgID, areaId: area?area.toString():'', roomId: room?room.toString():'' }));
    ref.current.updatePayLoad(JSON.stringify({ orgId: state.orgID, areaId: area?area.toString():'', roomId: room?room.toString():'' }));
    ref.current.updateMetaData(JSON.stringify({ orgId: state.orgID, areaId: area?area.toString():'', roomId: room?room.toString():'' }));
  }

  return (
    <Container>
      <h5>{t('menu.report')}</h5>
      <Row style={{ marginBottom: 5 }}>
        <Col lg={9}>
          <Row>
            <Col lg={4}>
              
              <Dropdown placeholder={t('common.area')} filter value={area} style={{ width: '100%' }} options={areaList} onChange={onAreaChange} optionLabel="name" optionValue="id" />
            </Col>
            <Col lg={4}>
              
              <Dropdown placeholder={t('common.room')} filter value={room} style={{ width: '100%' }} options={roomList} onChange={onRoomChange} optionLabel="name" optionValue="id" />
            </Col>
            <Col lg={2}>
              <Button label={t('button.search')} icon="pi pi-search" className="p-button-sm mr-1" onClick={() => search()} />
            </Col>
          </Row>
        </Col>

        <Col lg={3} style={{ textAlign: 'right' }}>
          <Button type="button" icon="pi pi-file-o" onClick={() => exportCSV(false)} className="mr-1" data-pr-tooltip="CSV" />
          <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success mr-1" data-pr-tooltip="XLS" />
          <Button type="button" icon="pi pi-file-pdf" onClick={exportPdf} className="p-button-warning p-mr-2" data-pr-tooltip="PDF" />

        </Col>

      </Row>
      <AppDataTable
        ref={ref}
        title={t('menu.report')}
        formId={FORM.ART_WORK}
        endpointUrl={ServiceEndPoint.ART_WORK.FETCH}
        payload={payload}
        bAllowActions={false}
        onAction={onAction} />

      <Toaster />
    </Container>

  );
}