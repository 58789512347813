import React, { useContext, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LoginContext } from '../auth';
import { AppDataTable } from '../common/components/table';
import { ServiceEndPoint } from '../constants';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { confirmDialog } from 'primereact/confirmdialog';
import '../assets/css/style.css';
import { ActionType, FORM } from '../constants/enum';
import toast, { Toaster } from 'react-hot-toast';
import { CommonDataService } from '../services';
import renderif from '../constants/renderif';
import { ArtWorkRequest } from './art-work-request';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

export const ArtWorkRequestList = () => {
    const { state } = useContext(LoginContext);
    const ref = useRef<any>(null);
    const { t } = useTranslation();
    const [payload, setPayLoad] = useState(JSON.stringify({ orgId: state.orgID }));
    const [displayBasic, setDisplayBasic] = useState(false);
    const [selectedObject, setSelectedObject] = useState('');
    const [isForm, setIsForm] = useState(false);
    const [isNew, setIsNew] = useState(false);
    const commonDataService = new CommonDataService();
    const onAction = (actionType: ActionType, selectedRows: any[]) => {
        switch (actionType) {
            case ActionType.ADD:
                addNew();
                break;
            case ActionType.EDIT:
                //edit(selectedRows);
                break;
            case ActionType.DELETE:
                
                break;
            default:
                break;
        }

    }

    const addNew = () => {
        setSelectedObject('');
        //setDisplayBasic(true);
        setIsForm(true);
    }

    const edit = () => {
        let selectedRows = ref.current.getSelectedRows();
        if (selectedRows === undefined || selectedRows.length === 0) {
            confirmAlert({
                title: t('common.message'),
                message: t('alert.select_at_least_one_record'),
                buttons: [
                    {
                        label: t('button.ok'),
                        onClick: () => { }
                    }
                ]
            });
        }
        else if (selectedRows !== undefined && selectedRows.length > 1) {
            confirmAlert({
                title: t('common.message'),
                message: t('alert.select_only_one_record'),
                buttons: [
                    {
                        label: t('button.ok'),
                        onClick: () => { }
                    }
                ]
            });
        }
        else {
            setSelectedObject(selectedRows[0].id);
        setIsForm(true);
        }
        
        
    }

    const onHide = () => {
        setIsForm(false);
    }

    const onSuccess = () => {
        setDisplayBasic(false);
        toast.success(t('alert.successfully_saved'), { position: 'top-right', duration: 3000 });
        ref.current.updateMetaData();

        setPayLoad(JSON.stringify({ orgId: state.orgID }));
    }




    return (
        <Container>
            {renderif(isForm === false)(
             <Row style={{ marginBottom: 5 }}>
                    <Col lg="3">
                        <h5>{t('menu.transfer_request')}</h5>
                    </Col>

                    <Col lg="9" style={{ textAlign: 'right' }}>
                        <Button label={t('button.view')} icon="pi pi-eye" className="p-button-sm mr-1" onClick={() => edit()} />
                        
                    </Col>

                </Row>
            )}
            {renderif(isForm === false)(
                <AppDataTable
                    ref={ref}
                    title={t('menu.transfer_request')}
                    formId={FORM.ART_WORK_REUEST}
                    endpointUrl={ServiceEndPoint.ART_WORK.FETCH_USER_REQUEST_ADMIN}
                    payload={payload}
                    bAllowActions = {false}
                    onAction={onAction} />
            )}
            {renderif(isForm === true)(
                <ArtWorkRequest onHide={onHide} onSuccess={onSuccess} requestId={selectedObject} bNew={isNew}></ArtWorkRequest>
            )}
            {/* <Dialog header={t('menu.mastersetup.product_category')} visible={displayBasic} style={{ width: '30vw' }}  onHide={() => onHide()}>
                    <ProductCategory onHide={onHide} onSuccess={onSuccess} groupId={selectedObject.groupId} id={selectedObject.id} name={selectedObject.name}></ProductCategory>
                </Dialog> */}
            <Toaster />
        </Container>

    );
}