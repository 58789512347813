import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Alert, InputGroup, Row } from 'react-bootstrap';
import { axiosInstance } from '../../lib';
import { departmentArray, relationArray } from '../';
import './complaint-form.css';

/**
 * @description Component Logs a user complaint into the system.
 * @output Renders two radio button groups, and two input fields,
 * saves them to state, and calls back-end API for complaint registeration.
 */

//validation for radio buttons
const radioErrors = { department: false, relation: false };
function validateRadios(department: number, relation: number) {
  if (department === 0) {
    radioErrors.department = true;
  }
  if (relation === 0) {
    radioErrors.relation = true;
  }
}
export function ComplaintForm() {
  const { t } = useTranslation();

  const [department, setDepartment] = useState(0);
  const [relation, setRelation] = useState(0);
  const [subject, setSubject] = useState('');
  const [complaint, setComplaint] = useState('');
  const [validated, setValidated] = useState(false);
  const [showModal, setShowModal] = useState(false);
  //checks to see if radio buttons are selected then removes error message
  if (department !== 0) {
    radioErrors.department = false;
  }
  if (relation !== 0) {
    radioErrors.relation = false;
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;
    validateRadios(department, relation);
    //if validation for form && radio buttons passes then this funtion Handles Submit
    if (
      form.checkValidity() &&
      radioErrors.department === false &&
      radioErrors.relation === false
    ) {
      axiosInstance({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/complaint/register`,
        data: {
          department,
          relation,
          subject,
          complaint,
          isVerified: true,
          isActive: true,
        },
      }).then((res) => {
        setShowModal(true);
      });
    }

    setValidated(true);
  };

  return (
    <div
      style={{
        margin: '50px auto 50px',
        maxWidth: '500px',
        backgroundColor: '#E7E7E7',
        padding: '35px 30px',
        borderRadius: '20px',
      }}
    >
      <h5>{t('complaints.formTitle')}</h5>
      <p>{t('complaints.formSubTitle')}</p>
      <hr />
      <Alert show={showModal} variant='success'>
        <Alert.Heading>{t('alert.success')}</Alert.Heading>
        <p>{t('alert.complaintMessage')}</p>
        <hr />
        <div className='d-flex justify-content-end'>
          <Button onClick={() => setShowModal(false)} variant='outline-success'>
            {t('button.close')}
          </Button>
        </div>
      </Alert>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group controlId='radioGroup1'>
          <p>{t('complaints.deptRadioTitle')}</p>

          <p className='radio-feedback'>
            {/* Error message displays below if radio button is not selected */}
            {radioErrors.department ? t('complaints.deptRadioError') : null}
          </p>
          {departmentArray.map((department) => (
            <Row
              className='text-center justify-content-left pl-4 radio-button'
              key={department.key}
            >
              <InputGroup.Radio
                label='Radio for following text input'
                name='deparmentSelection'
                value={department.value}
                onClick={(e: any) => setDepartment(e.target.value)}
              />
              <InputGroup.Text className='col-9 ml-3 border-0 bg-white font-weight-bold'>
                {t(department.label)}
              </InputGroup.Text>
            </Row>
          ))}
        </Form.Group>
        <Form.Group controlId='radioGroup2'>
          <p>{t('complaints.relRadioTitle')}</p>
          <p className='radio-feedback'>
            {/* Error message displays below if radio button is not selected */}
            {radioErrors.relation ? t('complaints.relRadioError') : null}
          </p>

          {relationArray.map((relation) => (
            <Row
              className='text-center justify-content-left pl-4 radio-button'
              key={relation.key}
            >
              <InputGroup.Radio
                label='Radio for following text input'
                name='relationSelection'
                value={relation.value}
                onClick={(e: any) => setRelation(e.target.value)}
              />
              <InputGroup.Text className='col-9 ml-3 border-0 bg-white font-weight-bold'>
                {t(relation.label)}
              </InputGroup.Text>
            </Row>
          ))}
        </Form.Group>
        <Form.Group controlId='subject'>
          <Form.Label>{t('complaints.subjectTitle')}</Form.Label>
          <Form.Control
            type='text'
            required
            placeholder={t('complaints.subjectPlaceholder')}
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
          <Form.Control.Feedback type='invalid'>
            {t('complaints.subjectError')}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId='message'>
          <Form.Label>{t('complaints.complaintTitle')}</Form.Label>
          <Form.Control
            as='textarea'
            rows={3}
            required
            value={complaint}
            placeholder={t('complaints.complaintPlaceholder')}
            onChange={(e) => setComplaint(e.target.value)}
          />
          <Form.Control.Feedback type='invalid'>
            {t('complaints.complaintError')}
          </Form.Control.Feedback>
        </Form.Group>

        <Button variant='dark' type='submit'>
          {t('button.submit')}
        </Button>
      </Form>
    </div>
  );
}
