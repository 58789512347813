import ReactDOM from 'react-dom';
import React, { useContext, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LoginContext } from '../../auth';
import { AppDataTable } from '../../common/components/table';
import { ServiceEndPoint } from '../../constants';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import toast, { Toaster } from 'react-hot-toast';
import { Dropdown } from 'primereact/dropdown';

import '../../assets/css/style.css';
import { CommonDataService } from '../../services';

interface TechniqueProps {
    id: number;
    name: string;
    onHide: () => void;
    onSuccess: () => void;
}

export const Technique = (props: TechniqueProps) => {
    const { state } = useContext(LoginContext);
    const [id, setId] = useState(props.id);
    const [value, setValue] = useState(props.name);
    
    const { t } = useTranslation();
    const commonDataService = new CommonDataService();


    const save = () => {
        commonDataService.executeApiCall(ServiceEndPoint.TECHNIQUE.SAVE,
            {
                orgId: state.orgID,
                userId: state.userId,
                id: id,
                name: value
            })
            .then(res => {
                if (res.status) {
                    if (res.data > 0) {
                        props.onSuccess();
                    }
                    else if (res.data === -1) {
                        toast.error(t('alert.duplicate_record'), { position: 'top-right', duration: 3000 });
                    }
                }
            });
    }



    return (
        <div style={{ marginTop: 10 }}>
            <p>{t('common.technique')}<span className="required">*</span></p>
            <InputText value={value} aria-describedby="product-group" onChange={(e) => setValue(e.target.value)} style={{ width: '100%' }} className="" />
            
            <div style={{ width: '100%', marginTop: 15, textAlign: 'right' }}>
                <Button label={t('button.cancel')} onClick={() => props.onHide()} className="p-button-text" />
                <Button label={t('button.submit')} onClick={() => save()} autoFocus />
            </div>
            <Toaster />
        </div>
    );
}