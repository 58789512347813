import React, { useContext } from 'react';
import { LoginContext } from '..';
import renderif from '../../constants/renderif';

export const LoginContainer = (props: {
  isLoggedIn: React.ReactNode;
  willLogIn: React.ReactNode;
  receptionLogin: React.ReactNode;
  employeeLogin: React.ReactNode;
}) => {
  const { state } = useContext(LoginContext);
  return (
    <div data-testid='login-container' style={{background: '#eeeeee'}} >
      {renderif((state.loggedIn || state.token) &&  state.userType === "1")(
          props.isLoggedIn
      )}
      {renderif((state.loggedIn || state.token) &&  state.userType === "2")(
          props.employeeLogin
      )}
       {renderif((state.loggedIn || state.token) &&  state.userType === "3")(
          props.receptionLogin
      )}
       {renderif(!(state.loggedIn || state.token))(
          props.willLogIn
      )}
    </div>
  );
};
