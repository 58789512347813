import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button, Row, Col, Alert, Container, Image } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { axiosInstance } from '../../lib';

import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';

import bg from '../../assets/images/bg.png';
import logo from '../../assets/images/logo.png';
import mlogo from '../../assets/images/mon_logo.png';
import { ServiceEndPoint } from '../../constants';
import { CommonDataService } from '../../services';
import renderif from '../../constants/renderif';
import { ToggleLocale } from '../../nav/components/toggle-locale';
import './auth.css';

import { Dialog } from 'primereact/dialog';

/**
 * @description Component Registers User to the system
 * @output Renders input fields that take in data required for registration,
 * saves them to state, and calls back-end API to register the user.
 */

export function RegistrationForm() {
  const { t, i18n } = useTranslation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [orgId, setOrgId] = useState('');
  const [accessibility, setAccessibility] = useState<Array<number>>([]);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validated, setValidated] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const [organisationList, setOrganisationList] = useState<any[]>([]);
  const [selectedOrg, setSelectedOrg] = useState('');
  const [department, setDepartment] = useState('');
  const [section, setSection] = useState('');
  const [domainName, setDomainName] = useState('');
  const [passcode, setPassCode] = useState('');

  const [displayBasic, setDisplayBasic] = useState(false);
  const [otp, setOTP] = useState('');

  const commonDataService = new CommonDataService();

  const onHide = () => {
    setDisplayBasic(false);
  }

  const onOrgChange = (e: any) => {
    setSelectedOrg(e.value);
    organisationList.forEach(item => {
      if (item.id === e.value) {
        setDomainName(`@${item.email_domain}`);
      }
    });

  }
  const getOrgList = () => {

    commonDataService.executeApiCall(ServiceEndPoint.ORGANISATION.FETCH_LIST, null).then((res: any) => {

      if (res.status) {
        setOrganisationList([]);
        let rData: any[] = [];
        res.data.forEach((item: any) => {
          rData.push(item);
        });
        setOrganisationList(rData);
      }
    });

  }


  useEffect(() => {
    getOrgList();
    // if (password !== confirmPassword) {
    //   setValidationMessage('Passwords do not match');
    // } else setValidationMessage('');
  }, []);

  const checkBoxToggle = (target: any) => {
    const { value, checked } = target;
    if (checked) {
      setAccessibility([...accessibility, parseInt(value, 10)]);
    } else {
      setAccessibility(
        accessibility.filter((element) => element !== parseInt(value, 10))
      );
    }
    console.log(parseInt(value, 10), { checked });
  };

  const sendOTP = ()=>{
    setDisplayBasic(true);
    commonDataService.sendOTP(
        {
            email: email + domainName,
            lang: i18n.language,
        }
        )
        .then((res) => {

          if (res.status) {
           setOTP(res.otp);
          
          }
          else  {
            alert(t("alert.error"));
          }
          
        });
  }

  const verifyOTP = ()=>{
    commonDataService.verifyOTP({email: email+ domainName, otp: passcode.toString()}).then((otp_res)=>{
      // console.log(otp_res);
      // return;
      if (otp_res.status && otp_res.data) {
        commonDataService.register
        (
          {
            name,
            orgId: selectedOrg,
            dept: department,
            section: section,
            email: email + domainName,
            password: passcode,
            type: 2,
            roleId: 1,
            isVerified: true,
            isActive: true,
            locale: i18n.language,

          }
        )
        .then((res) => {

          if (res.status) {
            alert(t("alert.user_created_successfully"));
            window.location.href = '/';
          }
          else if (res.status === false && res.error.indexOf("Duplicate") >= 0) {
            alert(t("alert.user_already_exists"));
          }
          setValidated(true);
        });
      }
      else{
        alert(t("alert.invalid_otp"));
      }
    });
    //if(passcode === otp.toString())
    {
 
    }
    // else{
    //  
    // }
  }

  const handleSubmit = (e: any) => {
    debugger;

    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      
      setPassCode('');
      sendOTP();

     

    }
    setValidated(true);
  };
  return (

    <Container fluid="md" className="authContainer bg" >
      <Row>
        <Col lg={8} className="authContainer loging-bg">
          <div className="w-100 d-lg-flex">
            <Image
              src={mlogo}
              fluid
              alt='logo'
              style={{width: 200, marginTop:10}}
            />
          </div>
          <div style={{ color:'#444', fontWeight:500, marginTop: 30}}>
            Telefon: 0499-170 00
            <br/>E-post: kommun@monsteras.se
            <br/>Postadress: Mönsterås kommun, Box 54, 383 22 Mönsterås
            <br/>Besöksadress: Kvarngatan 2 i Mönsterås

 

            <br/>Organisationsnummer: 212000-0720
            </div>
        </Col>
        <Col lg={4} className="authContainer loging-bg" style={{ backgroundColor: '#17D5F9', margin: 'auto', padding: 10, borderWidth: 1, borderColor: '#fefefe' }}>
          <div
            className="login p-2"
          >
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Image
                src={logo}
                fluid
                alt='logo'
                style={{ width: '100px' }}
              />
              <Form noValidate validated={validated} onSubmit={handleSubmit} style={{ textAlign: 'left' }}>
                <Form.Group controlId='name'>
                  <Form.Label className='font-weight-bold'>
                    {t('common.fullName')}
                  </Form.Label>
                  <Form.Control
                    required
                    type='name'
                    placeholder={t('common.fullNamePlaceholder')}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  {/* validation message */}
                  {/* <Form.Control.Feedback type='invalid'>
                      Name is required.
                    </Form.Control.Feedback> */}
                </Form.Group>

                <Form.Group controlId='orgId'>
                  <Form.Label className='font-weight-bold'>
                    {t('common.organisation')}
                  </Form.Label>
                  <Dropdown filter className="form-control" value={selectedOrg} style={{ width: '100%' }} options={organisationList} onChange={onOrgChange} optionLabel="orgName" optionValue="id" />
                  {/* validation message */}
                  {/* <Form.Control.Feedback type='invalid'>
                    Organisation is required.
                  </Form.Control.Feedback> */}
                </Form.Group>
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId='dept'>
                      <Form.Label className='font-weight-bold'>
                        {t('common.dept')}
                      </Form.Label>
                      <Form.Control
                        required
                        type='name'
                        placeholder={t('common.dept')}
                        value={department}
                        onChange={(e) => setDepartment(e.target.value)}
                      />
                      {/* validation message */}
                      {/* <Form.Control.Feedback type='invalid'>
                        Department is required.
                      </Form.Control.Feedback> */}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group controlId='section'>
                      <Form.Label className='font-weight-bold'>
                        {t('common.section')}
                      </Form.Label>
                      <Form.Control
                        required
                        type='name'
                        placeholder={t('common.section')}
                        value={section}
                        onChange={(e) => setSection(e.target.value)}
                      />
                      {/* validation message */}
                      {/* <Form.Control.Feedback type='invalid'>
                        Section is required.
                      </Form.Control.Feedback> */}
                    </Form.Group>
                  </Col>
                </Row>

                {renderif(selectedOrg !== '')(
                  <Form.Group controlId='email'>
                    <Form.Label className='font-weight-bold'>
                      {t('common.email')}
                    </Form.Label>

                    <div className="p-inputgroup">

                      <InputText required
                        type='text'
                        placeholder={t('common.emailPlaceholder')}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)} />
                      <span className="p-inputgroup-addon">{domainName}</span>
                    </div>
                    <Form.Text className='text-muted'>
                      {t('auth.registrationText')}
                    </Form.Text>
                    {/* validation message */}
                    {/* <Form.Control.Feedback type='invalid'>
                      Please enter a valid email address.
                    </Form.Control.Feedback> */}
                  </Form.Group>

                )}
                {/* <Form.Group controlId='password'>
                  <Form.Label className='font-weight-bold'>
                    {t('common.password')}
                  </Form.Label>
                  <Form.Control
                    required
                    minLength={8}
                    maxLength={16}
                    type='password'
                    placeholder={t('common.passwordPlaceholder')}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                 
                  <Form.Text className='text-muted'>
                  {t('common.password_strength')}
                    </Form.Text>
                </Form.Group>

                <Form.Group controlId='confirmPassword'>
                  <Form.Label className='font-weight-bold'>
                    {t('common.confirmPassword')}
                  </Form.Label>
                  <Form.Control
                    required
                    type='password'
                    placeholder={t('common.confirmPasswordPlaceholder')}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  
                  {validationMessage !== '' ? (
                    <Alert className='mt-1' variant='warning'>
                      {validationMessage}
                    </Alert>
                  ) : null}
                </Form.Group> */}

                <Button variant='dark' type='submit'>
                  {t('button.register')}
                </Button>

                <Row>
                  <Col className='text-right'>
                    <span>Already have an account? </span>
                    <NavLink to='/'>{t('button.login')}</NavLink>
                  </Col>
                </Row>
                <Row>
                  <Col lg={8}></Col>
                  <Col lg={4} className='text-right setting-icon m-0 p-0'>
                    <ToggleLocale isLoggedIn={true} />
                  </Col>
                </Row>
              </Form>

            </div>

          </div>
        </Col>
      </Row>
      <Dialog header={t('common.passcode')} visible={displayBasic} style={{ width: '30vw', justifyContent: 'center' }} onHide={() => onHide()}>
        <div style={{textAlign:'center'}}>
          {t('common.passscode-notice')}
        </div>
        <Form.Control
          required
          type='password'
          placeholder={t('common.passcode')}
          value={passcode}
          autoFocus
          onChange={(e) => setPassCode(e.target.value)}
          style={{ textAlign: 'center', width: '60%', margin: 'auto', marginTop:'10px' }}
        />
        <div style={{ textAlign: 'center', width: '60%', margin: 'auto', marginTop:'10px' }}>
          <Button variant='dark' onClick={() => verifyOTP()}
            style={{ textAlign: 'center', margin: 'auto' }}
          >
            {t('button.verify')}
          </Button>
        </div>
      </Dialog>
    </Container>




  );
}
