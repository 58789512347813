import React, { useEffect, useState } from 'react';
import { Col, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

/**
 * @description Component Switches Language
 * @output Renders two buttons that switch language between English and Swedish
 */

export function ToggleLocale(props: any) {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState('');
  const changeLanguage = (lng: string) => {
    debugger;
    localStorage.setItem('lang', lng);
    i18n.changeLanguage(lng);
    setLang(lng);
   
  };

  useEffect(() => {
    debugger;
    var lng = localStorage.getItem('lang') ? localStorage.getItem('lang')?.toString() : "sw";
    if (lng) {
      i18n.changeLanguage(lng);

      setLang(lng);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const popover = (
    <Popover id='popover-basic'>
      <Popover.Title as='h3'>{t('description.part1')}</Popover.Title>
      <Popover.Content>{t('description.part2')}</Popover.Content>
    </Popover>
  );
  const onChangeHandler = (option: string) => {
    changeLanguage(option);
  };
  return (
    <Col className='mt-2'>

      <select
        value={lang}
        style={{ background: 'transparent', borderWidth: 0, borderColor: '#fff', color: '#fff' }}
        onChange={(e) => onChangeHandler(e.target.value)}
      >
        <option style={{ color: "#444" }} value='en'>English</option>
        <option style={{ color: "#444" }} value='sw'>Swedish</option>
      </select>

    </Col>
  );
}
