import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import '../assets/css/style.css';
import { LoginContext } from '../auth';
import { ServiceEndPoint } from '../constants';
import { CommonDataService } from '../services';
import toast, { Toaster } from 'react-hot-toast';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { Galleria } from 'primereact/galleria';
import { Calendar } from 'primereact/calendar';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

interface ArtwokProps {
  location: string,
  onHide: () => void;
  onSuccess: (stock: any, location: string, pLocId: any, pLocText: any, pDate: any, pTime: any) => void;
}

export const AddStock = (props: ArtwokProps) => {
  const { state } = useContext(LoginContext);
  const { t } = useTranslation();
  const [stockList, setStockList] = useState<any[]>([]);
  const [selectedStock, setSelectedStock] = useState();
  const [stock, setStock] = useState<any>({});
  const [prefereLocList, setPrefereLocList] = useState<any>({});
  const [selectedLoc, setSelectedLoc] = useState();
  const [preferedLocation, setPreferedLocation] = useState<any>({});
  const [location, setLocation] = useState(props.location);
  const [date1, setDate1] = useState<any>();
  const [date9, setDate9] = useState('');
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [imageList, setImageList] = useState<any[]>([]);
  const [displayImag, setDisplayImage] = useState(false);

  const galleria1 = React.createRef<Galleria>();

  const format = 'h:mm a';

  let minDate = new Date();
  const responsiveOptions = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];

  const commonDataService = new CommonDataService();

  const onStockChange = (e: any) => {
    //setProperty("status", e.value);
    let stockFilter = stockList.filter(item => { return item.id === e.value });
    if (stockFilter && stockFilter.length > 0) {
      setStock(stockFilter[0]);
    }
    setSelectedStock(e.value);
  }

  const onLocationChange = (e: any) => {
    //setProperty("status", e.value);
    let filter = prefereLocList.filter((item: any) => { return item.id === e.value });
    if (filter && filter.length > 0) {
      setPreferedLocation(filter[0]);
    }
    setSelectedLoc(e.value);
  }
  const getStockList = () => {
    console.log(state.orgID);
    commonDataService.executeApiCall(ServiceEndPoint.ART_WORK.FETCH_FOR_REQUEST, { orgId: state.orgID }).then(res => {
      if (res.status) {
        setStockList([]);
        let rData: any[] = [];
        res.data.forEach((item: any) => {
          if (item.imageUrl !== '') {
            rData.push(item);
          }
        });
        setStockList(rData);
        //console.log(rData);
        viewImages(rData[0].images, rData[0].imageUrl, rData[0].title, rData[0].artistName, rData[0].signature, 0,rData[0].techniqueName, rData[0].height, rData[0].width, rData[0].depth)
      }
    });
  }



  const getPreferedLocations = () => {
    commonDataService.executeApiCall(ServiceEndPoint.PREFERED_LOCATION.FETCH, { orgId: state.orgID }).then(res => {
      if (res.status) {
        setPrefereLocList([]);
        let rData: any[] = [];
        res.data.forEach((item: any) => {

          rData.push(item);

        });
        setPrefereLocList(rData);
      }
    });
  }


  useEffect(() => {
    getPreferedLocations();
    getStockList();

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onChange = (value: any) => {

    if (value) {
      setDate9(value.format(format))
    }
    else {
      setDate9('');
    }
  }

  const saveChanges = () => {
    if(location===''){
      alert('Please enter location.');
      return;
    }
    props.onSuccess(stockList[selectedIndex], location, preferedLocation?.id, preferedLocation?.name, date1? date1.getFullYear() + '-' +(parseInt(date1.getMonth(),10)+1).toString().padStart(2,'0') + '-' + date1.getDate().toString().padStart(2,'0'):'', date9);
  }

  const artWorkOptionTemplate = (option: any) => {
    return (
      <div className="artwork-item">
        <div>
          <div style={{ width: 50, float: 'left' }} ><img src={`${process.env.REACT_APP_API_URL}/api${option.imageUrl}`} style={{ width: 40, height: 40 }} /></div>
          <div>{option.title}</div>
        </div>


      </div>
    );
  }

  const itemTemplate = (item: any) => {

    return <img src={`${process.env.REACT_APP_API_URL}/api${item.image}`} style={{ width: '100%', display: 'block' }} />
  }

  const thumbnailTemplate = (item: any) => {
    return <img src={`${process.env.REACT_APP_API_URL}/api${item.thumbnail}`} style={{ width: '100%', display: 'block' }} />
  }

  const captionTemplate = (item: any) => {
    return (
      <React.Fragment>
        <div className='row'>
          <div className='col-6'>
          <h6 className="p-mb-2">{item.title}</h6>
        <p><small><b>{item.artist}</b></small></p>
        <p><small><b>{item.sig}</b></small></p>
          </div>
          <div className='col-6'>
          <p><small><b>{t('common.technique')}: {item.technique}</b></small></p>
          <p><small><b>{t('size')}: {item.height?item.height+'cm':''}{item.width? " x " + item.width+'cm':''}{item.depth?' x '+ item.depth + 'cm':''}</b></small></p>
          </div>
        </div>
       
      </React.Fragment>
    );
  }

  const viewImages = (images: string, selectedImage: string, imgTitle: string, artist: string, signature: string, index: number, technique:string, h:string, w:string, d:string) => {
    let _imageList: any[] = [];
    let iList = images.split(",");
    debugger;
    images.split(",").forEach((item: any) => {
      console.log(item);
      if (item != '') {
        _imageList.push({
          image: item.replace("/100/100", "/2000/2000"),
          thumbnail: item,
          bSelected: item === selectedImage ? true : false,
          title: imgTitle,
          artist: artist,
          sig: signature,
          technique: technique,
          height: h,
          width: w,
          depth: d
        })
      }
    });

    setSelectedIndex(index);

    setActiveIndex(_imageList.length > 1 ? index : 0);

    setImageList(_imageList);
    setDisplayImage(true);
    if (galleria1.current) {
      galleria1.current.show();
    }
  }

  const selectedCountryTemplate = (option: any, props: any) => {
    if (option) {
      return (
        <div className="artwork-item">
          <div>
            <div style={{ width: 50, float: 'left' }} ><img src={`${process.env.REACT_APP_API_URL}/api${option.imageUrl}`} style={{ width: 40, height: 40 }} /></div>
            <div>{option.title}</div>
          </div>


        </div>
      );
    }

    return (
      <span>
        {props.placeholder}
      </span>
    );
  }

  return (
    <div style={{ marginTop: 10 }}>
      {/* <p>{t('common.artwork')}<span className="required">*</span></p>
      <Dropdown filter value={selectedStock} style={{width: '100%'}} options={stockList} 
      onChange={onStockChange} optionLabel="title" optionValue="id" placeholder={t("select")}
      itemTemplate={artWorkOptionTemplate}
      valueTemplate={selectedCountryTemplate} /> */}
      <Row>
        <Col lg={3}>
          <Row>
            {stockList.map((col, i) => {
              return <Col lg={5}><img onClick={() => viewImages(col.images, col.imageUrl, col.title, col.artistName, col.signature, i, col.techniqueName, col.height, col.width, col.depth)} src={`${process.env.REACT_APP_API_URL}/api${col.imageUrl}`} style={{ width: 80, height: 80 }} /></Col>
            })}
          </Row>
        </Col>
        <Col lg={5}>
          <Galleria ref={galleria1} showItemNavigators value={imageList} responsiveOptions={responsiveOptions} numVisible={5} style={{ maxWidth: '640px', width: '96%' }}
            caption={captionTemplate} activeIndex={activeIndex} onItemChange={(e) => setActiveIndex(e.index)} item={itemTemplate} thumbnail={thumbnailTemplate} />

        </Col>
        <Col lg={4}>
          <Row>
            <Col lg={12}>
              <div style={{ width: '100%', marginTop: 15 }}>
                <p>{t('common.location')}<span className="required">*</span></p>
                <InputText placeholder={t('common.location')} value={location} aria-describedby="product-group" onChange={(e) => setLocation(e.target.value)} style={{ width: '100%' }} className="" />
                <small className='text-danger'>ex. kommunhuset/ekonomi/rum</small>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <p>{t('common.prefered-location')}</p>
              <Dropdown filter value={selectedLoc} style={{ width: '100%' }} options={prefereLocList}
                onChange={onLocationChange} optionLabel="name" optionValue="id" placeholder={t("common.select")}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <p>{t('common.prefered_date')}</p>
              <Calendar dateFormat="yy-mm-dd" value={date1} onChange={(e) => setDate1(e.value)} minDate={minDate} />
            </Col>
            <Col lg={6}>
              <p>{t('common.prefered_time')}</p>
              {/* <TimePicker
                  showSecond={false}
                  
                  className="xxx"
                  onChange={setDate9}
                  format={format}
                  use12Hours
                  inputReadOnly
                /> */}


              <TimePicker
                showSecond={false}

                onChange={onChange}
                format={format}
                use12Hours
                inputReadOnly
              />

            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div style={{ width: '100%', marginTop: 15, textAlign: 'right' }}>
                <Button label={t('button.cancel')} onClick={() => props.onHide()} className="p-button-text" />
                <Button label={t('button.add')} onClick={() => saveChanges()} autoFocus />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* <small id="product-group" className="p-error">Area, Room</small> */}

      {/* <Toaster /> */}
    </div>
  );
};
