import React, { useContext, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LoginContext } from '../../auth';
import { AppDataTable } from '../../common/components/table';
import { ServiceEndPoint } from '../../constants';
import { Dialog } from 'primereact/dialog';
import {ProductStatus} from './status'
import { confirmDialog } from 'primereact/confirmdialog';
import '../../assets/css/style.css';
import { ActionType, FORM } from '../../constants/enum';
import toast, { Toaster } from 'react-hot-toast';
import { CommonDataService } from '../../services';

export const StatusList = () => {
    const { state } = useContext(LoginContext);
    const ref = useRef<any>(null);
    const { t } = useTranslation();
    const [payload, setPayLoad] = useState(JSON.stringify({ orgId: state.orgID }));
    const [displayBasic, setDisplayBasic] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState({statusId: 0, statusName: '', allowTransfer:0});

    const commonDataService = new CommonDataService();
    const onAction = (actionType: ActionType, selectedRows: any[]) => {
        switch(actionType){
            case ActionType.ADD:
                addNew();        
                break;
            case ActionType.EDIT:
                editProductGroup(selectedRows);
                break;
            case ActionType.DELETE:
                deleteConfirm(selectedRows);
                break;
            default:
                break;
        }
        
    }

    const addNew = ()=>{
        setSelectedStatus({statusId: 0, statusName: '', allowTransfer: 0});
        setDisplayBasic(true);
    }

    const editProductGroup = (selectedRows: any[]) =>{
        setSelectedStatus({statusId: selectedRows[0].id, statusName: selectedRows[0].statusName, allowTransfer: selectedRows[0].allowTransfer});
        setDisplayBasic(true);
    }
    
    const onHide = () => {
        setDisplayBasic(false);
    }

    const onSuccess = () => {
        setDisplayBasic(false);
        toast.success(t('alert.successfully_saved'),{position:'top-right', duration:3000});
        ref.current.updateMetaData();
        
        setPayLoad(JSON.stringify({ orgId: state.orgID }));
    }

    const deleteConfirm = (selectedRows: any[]) => {
        confirmDialog({
            message: t('alert.delete_message'),
            header: t('alert.delete_confirmation'),
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: t('button.yes'),
            rejectLabel: t('button.no'),
            accept: ()=>{
                commonDataService.executeApiCall(ServiceEndPoint.STATUS.DELETE, {statusIds: selectedRows.map(item=> item.id).join(), userId: state.userId})
                .then(res=>{
                    console.log(res);
                    if(res.status){
                        toast.success(t('alert.record_deleted'),{position:'top-right', duration:3000});
                        ref.current.updateMetaData();
                    }
                    else{
                        toast.error(t('alert.error'),{position:'top-right', duration:3000});
                    }
                });
            }
        });
    };

    return (
        <Container>
            <AppDataTable
            ref={ref}
            title={t('menu.mastersetup.status')}
                formId={FORM.STATUS}
                endpointUrl={ServiceEndPoint.STATUS.FETCH}
                payload={payload}
                onAction={onAction} />
                <Dialog header={t('menu.mastersetup.status')} visible={displayBasic} style={{ width: '30vw' }}  onHide={() => onHide()}>
                    <ProductStatus onHide={onHide} onSuccess={onSuccess} statusId={selectedStatus.statusId} statusName={selectedStatus.statusName} allowTransfer={selectedStatus.allowTransfer}></ProductStatus>
                </Dialog>
                <Toaster />
        </Container>
        
    );
}