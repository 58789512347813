import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button, Row, Col, Container, Image, Dropdown } from 'react-bootstrap';
import { LoginContext, LoginAction } from '..';
import { NavLink } from 'react-router-dom';
import { CommonDataService } from '../../services';
import './auth.css';
import bg from '../../assets/images/bg.png';
import logo from '../../assets/images/logo.png';
import mlogo from '../../assets/images/mon_logo.png';
import { ToggleLocale } from '../../nav/components/toggle-locale';

import { Dialog } from 'primereact/dialog';
import { LoginState } from '../types';

/**
 * @description Component Logs user into the system.
 * @output Renders two input fields take in email and password,
 * saves them to state,  and calls back-end API for user Login.
 * Then it calls context-dispatch that updates the context, and saves resulting token/hash (currently hardcoded) in sessionsStorage
 */

export function LoginForm({ setLoggedIn }: any) {
  const loginContext = useContext(LoginContext);
  const { t, i18n } = useTranslation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validated, setValidated] = useState(false);

  const [passcode, setPassCode] = useState('');

  const [displayBasic, setDisplayBasic] = useState(false);
  const [otp, setOTP] = useState('');

  const [userlogin, setLogin] = useState<LoginAction>();

  const commonDataService = new CommonDataService();

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity()) {
      //sendOTP();

      commonDataService.login
        (
          { email: email, password: password }
        )
        .then((res) => {
          console.log(res);

          if (res.status === true) {
            sendOTP();
          }

          // if (res.status === true) {

          //   const login: LoginAction = {
          //     type: 'login',
          //     payload: {
          //       userId: res.data.context.uid,
          //       token: res.data.access_token,
          //       orgID: res.data.context.orgId,
          //       userRole: res.data.context.role,
          //       refreshToken: res.data.refreshToken,
          //       userType: res.data.context.type.toString(),
          //       name: res.data.context.name
          //     },
          //   };
          //   debugger;
          //   if (res.data.context.type === "2") {

          //     setLogin(login);
          //     setPassCode('');
          //     sendOTP();
          //   }
          //   else {
          //     loginContext.dispatch(login);
          //   }
          // }
          // else {
          //   alert(res.error);
          // }
        });



    }
    setValidated(true);
  };

  const onHide = () => {
    setDisplayBasic(false);
  }

  const sendOTP = () => {
    setDisplayBasic(true);
    commonDataService.sendOTP(
      {
        email: email,
        lang: i18n.language,
      }
    )
      .then((res) => {
        console.log(res);
        if (res.status) {
          setOTP(res.otp);
          
        }
        else {
          alert(t("alert.error"));
        }

      });
  }

  const verifyOTP = () => {
    if (passcode) 
    {
      commonDataService.verifyLoginOTP
        (
          { email: email, password: passcode.toString() }
        )
        .then((res) => {


          if (res.status === true) {

            const login: LoginAction = {
              type: 'login',
              payload: {
                userId: res.data.context.uid,
                token: res.data.access_token,
                orgID: res.data.context.orgId,
                userRole: res.data.context.role,
                refreshToken: res.data.refreshToken,
                userType: res.data.context.type.toString(),
                name: res.data.context.name
              },
            };
            debugger;
            loginContext.dispatch(login);
          }
          else {
            alert(t("alert.invalid_otp"));
          }
        });
    }
    
  }

  return (
    <Container fluid="md" className="authContainer bg">
      <Row>
        <Col lg={8} className="authContainer loging-bg">
          <div className="w-100 d-lg-flex">
            <Image
              src={mlogo}
              fluid
              alt='logo'
              style={{ width: 200, marginTop: 10 }}
            />

          </div>
          <div style={{ color: '#444', fontWeight: 500, marginTop: 30 }}>
            Telefon: 0499-170 00
            <br />E-post: kommun@monsteras.se
            <br />Postadress: Mönsterås kommun, Box 54, 383 22 Mönsterås
            <br />Besöksadress: Kvarngatan 2 i Mönsterås



            <br />Organisationsnummer: 212000-0720
          </div>
        </Col>
        <Col lg={4} className="authContainer loging-bg" style={{ backgroundColor: '#17D5F9', padding: 10, borderRadius: 4, borderWidth: 1, borderColor: '#fefefe' }}>
          <div
            className="login p-2 align-items-center"
          >
            <div style={{ width: '100%', textAlign: 'center', marginBottom: '40px' }}>
              <Image
                src={logo}
                fluid
                alt='logo'
                style={{ width: '100px' }}
              />

            </div>
            <h5>{t('auth.logInToAccount')}</h5>
            <br />
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group controlId='email'>
                <Form.Label>{t('common.email')}</Form.Label>
                <Form.Control
                  type='text'
                  required
                  placeholder={t('common.emailPlaceholder')}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {/* <Form.Text className='text-muted'>
                  {t('auth.loginFormText')}
                </Form.Text> */}
                <Form.Control.Feedback type='invalid'>
                  Please enter a valid email address.
                </Form.Control.Feedback>
              </Form.Group>
              {/* <Form.Group controlId='password'>
                <Form.Label>{t('common.password')}</Form.Label>
                <Form.Control
                  type='password'
                  required
                  placeholder={t('common.passwordPlaceholder')}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Form.Control.Feedback type='invalid'>
                  Please enter your password.
                </Form.Control.Feedback>
              </Form.Group> */}
              <Button variant='dark' type='submit'>
                {t('button.submit')}
              </Button>
            </Form>
            {/* <Row>
              <Col className='text-right'>
                <NavLink to='/forgotPassword'>{t('button.forgotPassword')}</NavLink>
              </Col>
            </Row> */}
            <Row>
              <Col className='text-right pt-5'>
                <span>{t('common.dont_have_account')} </span>
                <NavLink to='/register'>{t('button.register')}</NavLink>
              </Col>
            </Row>
            <Row>
              <Col lg={8}></Col>
              <Col lg={4} className='text-right setting-icon m-0 p-0'>
                <ToggleLocale isLoggedIn={true} />
              </Col>
            </Row>

          </div>
        </Col>
      </Row>
      <Dialog header={t('common.passcode')} visible={displayBasic} style={{ width: '30vw', justifyContent: 'center' }} onHide={() => onHide()}>
        <div style={{ textAlign: 'center' }}>
          {t('common.passscode-notice')}
        </div>
        <Form.Control
          required
          type='password'
          placeholder={t('common.passcode')}
          value={passcode}
          autoFocus
          onChange={(e) => setPassCode(e.target.value)}
          style={{ textAlign: 'center', width: '60%', margin: 'auto', marginTop: '10px' }}
        />
        <div style={{ textAlign: 'center', width: '60%', margin: 'auto', marginTop: '10px' }}>
          <Button variant='dark' onClick={() => verifyOTP()}
            style={{ textAlign: 'center', margin: 'auto' }}
          >
            {t('button.verify')}
          </Button>
        </div>
      </Dialog>
    </Container>

  );
}
