import React, { useContext, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LoginContext } from '../auth';
import { ServiceEndPoint } from '../constants';
import { Button } from 'primereact/button';
import toast, { Toaster } from 'react-hot-toast';
import { Calendar } from 'primereact/calendar';
//import { v4 as uuidv4 } from 'uuid';
import { InputTextarea } from 'primereact/inputtextarea';
import '../assets/css/style.css';
import { CommonDataService } from '../services';
import renderif from '../constants/renderif';
import { Dropdown } from 'primereact/dropdown';
import Moment from 'moment';

import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { Col, Row } from 'react-bootstrap';

interface RequestProps {
    stockId: string;
    requestId: number,
    preferedLocation: number,
    type: number,
    onHide: () => void;
    onSuccess: () => void;
}

export const ApproveRequest = (props: RequestProps) => {
    const { state } = useContext(LoginContext);
    const [id, setId] = useState(props.stockId);
    const [receiveddate, setReceivedDate] = useState<any>();
    const [date9, setDate9] = useState('');
    const [reason, setReason] = useState('');
    const [area, setArea] = useState(-1);
    const [room, setRoom] = useState(-1);
    const [areaList, setAreaList] = useState<any[]>([]);
    const [roomList, setRoomList] = useState<any[]>([]);

    const [prefereLocList, setPrefereLocList] = useState<any>({});
  const [selectedLoc, setSelectedLoc] = useState(props.preferedLocation);
  const [preferedLocation, setPreferedLocation] = useState<any>({});

    const { t } = useTranslation();
    const commonDataService = new CommonDataService();

    const format = 'h:mm a';

    useEffect(() => {
        getPreferedLocations();
        getAreaList();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onChange = (value: any) => {

        if (value) {
            setDate9(value.format(format))
        }
        else {
            setDate9('');
        }
    }
    const save = (statusId: number) => {
        commonDataService.executeApiCall(ServiceEndPoint.ART_WORK.UPDATE_STOCK_REQUEST,
            {
                orgId: state.orgID,
                userId: state.userId,
                status: statusId,
                stockReceiveDate: statusId===2000? Moment(receiveddate).format("yyyy-MM-DD"):"",
                stockReceiveTime: statusId===2000?date9:"",
                pickupLocation: statusId===2000 && preferedLocation?preferedLocation.id:'',
                areaId: statusId===2000?area:"",
                roomId: statusId===2000?room:"",
                requestId: props.requestId,
                stockId: "'" + props.stockId.replace(",", "','") + "'",
                reason: reason
            })
            .then(res => {
                console.log(res);
                if (res.status) {
                    if (res.data > 0) {
                        props.onSuccess();
                    }
                    else if (res.data === -1) {
                        toast.error(t('alert.duplicate_record'), { position: 'top-right', duration: 3000 });
                    }
                }
            });
    }

    const onAreaChange = (e: any) => {
        setArea(e.value);
        getRoomList(e.value);
    }
    const getAreaList = () => {
        commonDataService.executeApiCall(ServiceEndPoint.AREA.FETCH, { orgId: state.orgID }).then(res => {
            if (res.status) {
                setAreaList([]);
                let rData: any[] = [];
                res.data.forEach((item: any) => {
                    rData.push(item);
                });
                setAreaList(rData);
            }
        });
    }
    const onRoomChange = (e: any) => {
        setRoom(e.value);
    }
    const getRoomList = (areaId: number) => {
        commonDataService.executeApiCall(ServiceEndPoint.AREA_ROOM.FETCH, { orgId: state.orgID }).then(res => {
            if (res.status) {
                setRoomList([]);
                let rData: any[] = [];
                res.data.forEach((item: any) => {
                    if (item.areaId === areaId) {
                        rData.push(item);
                    }
                });
                setRoomList(rData);
            }
        });
    }

    const onLocationChange = (e: any) => {
        //setProperty("status", e.value);
        let filter = prefereLocList.filter((item: any) => { return item.id === e.value });
        if (filter && filter.length > 0) {
          setPreferedLocation(filter[0]);
        }
        setSelectedLoc(e.value);
      }

    const getPreferedLocations = () => {
        commonDataService.executeApiCall(ServiceEndPoint.PREFERED_LOCATION.FETCH, { orgId: state.orgID }).then(res => {
          if (res.status) {
            setPrefereLocList([]);
            let rData: any[] = [];
            res.data.forEach((item: any) => {
    
              rData.push(item);

              if(item.id.toString() === props.preferedLocation.toString()){
                setPreferedLocation(item);
              }
    
            });
            setPrefereLocList(rData);
          }
        });
      }

    return (
        <div style={{ marginTop: 10 }}>
            {renderif(props.type === 1)(
                <div>
                    <Row>
                        <Col lg={6}>
                            <p>{t('common.receiveddate')}<span className="required">*</span></p>
                            <Calendar dateFormat="yy-mm-dd" value={receiveddate} aria-describedby="product-group" onChange={(e) => setReceivedDate(e.value)} style={{ width: '100%' }} className="" />
                        </Col>
                        <Col lg={6}>
                            <p>{t('common.receivedtime')}<span className="required">*</span></p>
                            <TimePicker
                                showSecond={false}
                                className="p-inputtext"
                                onChange={onChange}
                                format={format}
                                use12Hours
                                inputReadOnly
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <p>{t('common.prefered-location')}</p>
                            <Dropdown filter value={selectedLoc} style={{ width: '100%' }} options={prefereLocList}
                                onChange={onLocationChange} optionLabel="name" optionValue="id" placeholder={t("common.select")}
                            />
                        </Col>
                    </Row>
                    <p>{t('common.area')}<span className="required">*</span></p>
                    <Dropdown filter value={area} style={{ width: '100%' }} options={areaList} onChange={onAreaChange} optionLabel="name" optionValue="id" />
                    <p>{t('common.room')}<span className="required">*</span></p>
                    <Dropdown filter value={room} style={{ width: '100%' }} options={roomList} onChange={onRoomChange} optionLabel="name" optionValue="id" />
                    <div style={{ width: '100%', marginTop: 15, textAlign: 'right' }}>
                        <Button label={t('button.cancel')} onClick={() => props.onHide()} className="p-button-text" />
                        <Button label={t('button.approve')} onClick={() => save(2000)} autoFocus />
                    </div>
                    <Toaster />
                </div>
            )}
            {renderif(props.type === 2)(
                <div>
                    <p>{t('common.reason')}<span className="required">*</span></p>
                    <InputTextarea rows={3} value={reason} aria-describedby="product-group" onChange={(e) => setReason(e.target.value)} style={{ width: '100%' }} className="" />
                    <div style={{ width: '100%', marginTop: 15, textAlign: 'right' }}>
                        <Button label={t('button.cancel')} onClick={() => props.onHide()} className="p-button-text" />
                        <Button label={t('button.reject')} className="p-button-danger" onClick={() => save(3000)} autoFocus />
                    </div>
                    <Toaster />
                </div>
            )}
            <Toaster />
        </div>
    );
}