import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button, Alert } from 'react-bootstrap';
import { axiosInstance } from '../../lib';
import { LoginContext } from '../contexts/login.context';
import { CommonDataService } from '../../services';


interface ChangePasswordProps {
  
  onHide: () => void;
}

/**
 * @description Component let's user change their existing password.
 * @output Renders three input fields take in old-password, new-password and confirm-password,
 * @objective saves them to state,  and calls back-end API for user-change-password.
 */

export const ChangePassword = (props: ChangePasswordProps) => {
  const { t } = useTranslation();
  const { state } = useContext(LoginContext);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validated, setValidated] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');

  const commonDataService = new CommonDataService();

  useEffect(() => {
    if (newPassword !== confirmPassword) {
      setValidationMessage('Passwords do not match');
    } else setValidationMessage('');
  }, [newPassword, confirmPassword]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      
      commonDataService.changePassword({
        userId: state.userId,
        password: newPassword
      }).then((res: any)=>{
        if(res.status){
          alert("Password has been changed successfully.");
          props.onHide();
        }
        else{
          alert(res.error);
        }
      });
    }
    setValidated(true);
  };
  return (
    <div
     
    >
     
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
 

        <Form.Group controlId='newPassword'>
          <Form.Label>{t('auth.newPassword')}</Form.Label>
          <Form.Control
            type='password'
            required
            placeholder={t('auth.newPasswordPlaceholder')}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          {/* validation message */}
          <Form.Control.Feedback type='invalid'>
            Password must be between 8-16 characters
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId='confirmPassword'>
          <Form.Label>{t('auth.confirmNewPassword')}</Form.Label>
          <Form.Control
            type='password'
            required
            placeholder={t('auth.confirmNewPasswordPlaceholder')}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {/* validation message */}
          <Form.Control.Feedback type='invalid'>
            Please confirm your password.
          </Form.Control.Feedback>
          {validationMessage !== '' ? (
            <Alert className='mt-1' variant='warning'>
              {validationMessage}
            </Alert>
          ) : null}
        </Form.Group>

        <Button variant='dark' type='submit'>
          Submit
        </Button>
      </Form>
    </div>
  );
}