import React, { useContext, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LoginContext } from '../../auth';
import { ServiceEndPoint } from '../../constants';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import toast, { Toaster } from 'react-hot-toast';
import { Checkbox } from 'primereact/checkbox';
import '../../assets/css/style.css';
import { CommonDataService } from '../../services';
import { Galleria } from 'primereact/galleria';

interface StatusProps {
    imageList: any[];
    
    onHide: () => void;
}

export const ImageViewer = (props: StatusProps) => {
    const { state } = useContext(LoginContext);
    const { t } = useTranslation();
    const [imageList, setImageList] = useState(props.imageList);
    const [activeIndex, setActiveIndex] = useState(0);
    
    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 5
        },
        {
            breakpoint: '768px',
            numVisible: 3
        },
        {
            breakpoint: '560px',
            numVisible: 1
        }
    ];

    useEffect(() => {
        let index = imageList.findIndex(item=> item.bSelected === true);
        setActiveIndex(index);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const itemTemplate = (item: any)=> {
        
        return <img src={`${process.env.REACT_APP_API_URL}/api${item.image}`} style={{ width: '100%', display: 'block' }} />
    }

    const thumbnailTemplate = (item: any)=> {
        return <img src={`${process.env.REACT_APP_API_URL}/api${item.thumbnail}`}  style={{ width: '100%', display: 'block' }} />
    }

    return (
        <div>
           <Galleria showItemNavigators  value={imageList} responsiveOptions={responsiveOptions} numVisible={5} style={{ maxWidth: '640px' }}
               activeIndex={activeIndex} onItemChange={(e) => setActiveIndex(e.index)}         item={itemTemplate} thumbnail={thumbnailTemplate} />
        </div>
    );
}