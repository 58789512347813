import React, { useContext, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LoginContext } from '../../auth';
import { AppDataTable } from '../../common/components/table';
import { ServiceEndPoint } from '../../constants';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import {ProductCategory} from './product-category'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import '../../assets/css/style.css';
import { ActionType, FORM } from '../../constants/enum';
import toast, { Toaster } from 'react-hot-toast';
import { CommonDataService } from '../../services';

export const ProductCategoryList = () => {
    const { state } = useContext(LoginContext);
    const ref = useRef<any>(null);
    const { t } = useTranslation();
    const [payload, setPayLoad] = useState(JSON.stringify({ orgId: state.orgID }));
    const [displayBasic, setDisplayBasic] = useState(false);
    const [selectedObject, setSelectedObject] = useState({groupId: 0, id: 0, name: ''});

    const commonDataService = new CommonDataService();
    const onAction = (actionType: ActionType, selectedRows: any[]) => {
        switch(actionType){
            case ActionType.ADD:
                addNew();        
                break;
            case ActionType.EDIT:
                edit(selectedRows);
                break;
            case ActionType.DELETE:
                deleteConfirm(selectedRows);
                break;
            default:
                break;
        }
        
    }

    const addNew = ()=>{
        setSelectedObject({groupId: 0, id: 0, name: ''});
        setDisplayBasic(true);
    }

    const edit = (selectedRows: any[]) =>{
        setSelectedObject({groupId: selectedRows[0].groupId, id: selectedRows[0].id, name: selectedRows[0].category});
        setDisplayBasic(true);
    }
    
    const onHide = () => {
        setDisplayBasic(false);
    }

    const onSuccess = () => {
        setDisplayBasic(false);
        toast.success(t('alert.successfully_saved'),{position:'top-right', duration:3000});
        ref.current.updateMetaData();
        
        setPayLoad(JSON.stringify({ orgId: state.orgID }));
    }

    const deleteConfirm = (selectedRows: any[]) => {
        confirmDialog({
            message: t('alert.delete_message'),
            header: t('alert.delete_confirmation'),
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: t('button.yes'),
            rejectLabel: t('button.no'),
            accept: ()=>{
                commonDataService.executeApiCall(ServiceEndPoint.PRODUCT_CATEGORY.DELETE, {ids: selectedRows.map(item=> item.id).join(), userId: state.userId})
                .then(res=>{
                    console.log(res);
                    if(res.status){
                        toast.success(t('alert.record_deleted'),{position:'top-right', duration:3000});
                        ref.current.updateMetaData();
                    }
                    else{
                        toast.error(t('alert.error'),{position:'top-right', duration:3000});
                    }
                });
            }
        });
    };

    
    return (
        <Container>
            <AppDataTable
            ref={ref}
            title={t('menu.mastersetup.product_category')}
                formId={FORM.PRODUCT_CATEGORY}
                endpointUrl={ServiceEndPoint.PRODUCT_CATEGORY.FETCH}
                payload={payload}
                onAction={onAction} />
                <Dialog header={t('menu.mastersetup.product_category')} visible={displayBasic} style={{ width: '30vw' }}  onHide={() => onHide()}>
                    <ProductCategory onHide={onHide} onSuccess={onSuccess} groupId={selectedObject.groupId} id={selectedObject.id} name={selectedObject.name}></ProductCategory>
                </Dialog>
                <Toaster />
        </Container>
        
    );
}