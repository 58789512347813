import React, { useContext, useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import '../assets/css/style.css';
import { LoginContext } from '../auth';
import { ServiceEndPoint } from '../constants';
import { CommonDataService } from '../services';
import toast, { Toaster } from 'react-hot-toast';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { AddStock } from './add-stock';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { AppDataTable } from '../common/components/table';
import { ActionType, FORM } from '../constants/enum';
import renderif from '../constants/renderif';

interface ArtwokProps {
  requestId: any;
  bNew: boolean;
  onHide: () => void;
  onSuccess: () => void;
}

export const ArtWorkUserRequest = (props: ArtwokProps) => {
  const { state } = useContext(LoginContext);
  const { t } = useTranslation();
  const [payload, setPayLoad] = useState(JSON.stringify({ requestId: props.requestId }));
  const [displayBasic, setDisplayBasic] = useState(false);

  const [cols, setCols] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>();

  const [location, setLocation] = useState('');

  const ref = useRef<any>(null);

  const commonDataService = new CommonDataService();
  const saveChanges = () => {
    commonDataService.executeApiCall(ServiceEndPoint.ART_WORK.SAVE_REQUEST,
      {
        orgId: '67ad9fc6-cb4a-11eb-83ec-00090ffe0001', //state.orgID,
        userId: state.userId,
        stockList: rows
      })
      .then(res => {
        console.log(res);
        if (res.status) {
          if (res.data > 0) {
            props.onSuccess();
          }
          else if (res.data === -1) {
            toast.error(t('alert.duplicate_record'), { position: 'top-right', duration: 3000 });
          }
        }
      });
  }

  useEffect(() => {
    let _cols: any[] = [];
    _cols.push({ id: 1, formId: 1, colName: 'image', headerText: 'image', colWidth: 70, colAlignment: 1, colFormat: '', colSeqNo: 1, colIsVisible: 1, colTranslate: 0, colType: 4 });
    _cols.push({ id: 2, formId: 1, colName: 'title', headerText: 'title', colWidth: 0, colAlignment: 1, colFormat: '', colSeqNo: 2, colIsVisible: 1, colTranslate: 0, colType: 1 });
    _cols.push({ id: 3, formId: 1, colName: 'location', headerText: 'location', colWidth: 250, colAlignment: 1, colFormat: '', colSeqNo: 3, colIsVisible: 1, colTranslate: 0, colType: 1 });
    _cols.push({ id: 3, formId: 1, colName: 'p_loc_name', headerText: 'prefered-location', colWidth: 250, colAlignment: 1, colFormat: '', colSeqNo: 4, colIsVisible: 1, colTranslate: 0, colType: 1 });
    _cols.push({ id: 3, formId: 1, colName: 'p_date', headerText: 'prefered_date', colWidth: 80, colAlignment: 1, colFormat: '', colSeqNo: 5, colIsVisible: 1, colTranslate: 0, colType: 1 });
    _cols.push({ id: 3, formId: 1, colName: 'p_time', headerText: 'prefered_time', colWidth: 80, colAlignment: 1, colFormat: '', colSeqNo: 6, colIsVisible: 1, colTranslate: 0, colType: 1 });
    _cols.push({ id: 4, formId: 1, colName: 'status', headerText: 'status', colWidth: 150, colAlignment: 1, colFormat: '', colSeqNo: 7, colIsVisible: 1, colTranslate: 0, colType: 1 })
    setCols(_cols);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  const addNew = () => {
    if (rows.length > 0) {
      setLocation(rows[rows.length - 1].location);
    }
    setDisplayBasic(true);
  }

  const onHide = () => {
    setDisplayBasic(false);
  }

  const onSuccess = (stock: any, location: string, pLocId: any, pLocText: any, pDate: any, pTime: any) => {
    setDisplayBasic(false);
    let _rows = [...rows];
    let row = _rows.find(r => r.stockId === stock.id);
    if (row) {
      row.location = location;
      row.p_loc_id = pLocId;
      row.p_loc_name = pLocText;
      row.p_date = pDate;
      row.p_time = pTime;
    }
    else {
      _rows.push({
        stockId: stock.id,
        image: stock.imageUrl.replace('/100/100', "/40/40"),
        title: stock.title,
        location: location,
        status: t('common.pending'),
        p_loc_id: pLocId,
        p_loc_name: pLocText,
        p_date: pDate,
        p_time: pTime
      });
    }

    setRows(_rows);

  }

  const renderDate = (rowData: any, column: any) => {
    let col = cols.find(a => a.colName === column.field);
    return <span>{Moment(rowData[column.field]).format(col.colFormat)}</span>;
  }

  const renderImage = (rowData: any, column: any) => {
    if (rowData[column.field] !== '') {
      return <img src={`${process.env.REACT_APP_API_URL}/api${rowData[column.field]}`} style={{ width: 40, height: 40 }} />
    }
    return '';
  }


  const dynamicColumns = cols.map((col, i) => {
    if (col.colType === 2) {
      return <Column key={i} field={col.colName} header={t("common." + col.headerText)} filter sortable={true} headerStyle={{ width: col.colWidth === 0 ? '100%' : col.colWidth.toString() + 'px' }} />;
    }
    else if (col.colType === 3) {
      return <Column key={i} field={col.colName} body={renderDate} header={t("common." + col.headerText)} filter sortable={true} headerStyle={{ width: col.colWidth === 0 ? '100%' : col.colWidth.toString() + 'px' }} />;
    }
    else if (col.colType === 4) {
      return <Column key={i} field={col.colName} body={renderImage} header={t("common." + col.headerText)} filter sortable={true} headerStyle={{ width: col.colWidth === 0 ? '100%' : col.colWidth.toString() + 'px' }} />;
    }

    return <Column key={i} field={col.colName} header={t("common." + col.headerText)} filter sortable={true} headerStyle={{ width: col.colWidth === 0 ? '100%' : col.colWidth.toString() + 'px' }} />;
  });

  const removeRows = () => {

  }

  const onDeleteRequest = () => {
    let selectedRows = ref.current.getSelectedRows();
    if (selectedRows && selectedRows.length > 0) {

      confirmDialog({
        message: t('alert.delete_message'),
        header: t('alert.delete_confirmation'),
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        acceptLabel: t('button.yes'),
        rejectLabel: t('button.no'),
        accept: () => {
          let items = selectedRows.filter((item: any)=>{return item.status === 1000});
          commonDataService.executeApiCall(ServiceEndPoint.ART_WORK.DELETE_STOCK_REQUEST, { ids: items.map((item:any) => item.requestDetailId).join(), userId: state.userId })
          .then(res => {
              console.log(res);
              if (res.status) {
                  toast.success(t('alert.record_deleted'), { position: 'top-right', duration: 3000 });
                  ref.current.updateMetaData();
              }
              else {
                  toast.error(t('alert.error'), { position: 'top-right', duration: 3000 });
              }
          });
        }
      });
    }
    else {
      confirmAlert({
        title: t('common.message'),
        message: t('alert.select_at_least_one_record'),
        buttons: [
          {
            label: t('button.ok'),
            onClick: () => { }
          }
        ]
      });
    }
  }

  const onDelete = () => {
    if (selectedRows && selectedRows.length > 0) {
      confirmDialog({
        message: t('alert.delete_message'),
        header: t('alert.delete_confirmation'),
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        acceptLabel: t('button.yes'),
        rejectLabel: t('button.no'),
        accept: () => {
          let _rows = [...rows];
          selectedRows.forEach(item => {
            let index = _rows.indexOf(item);
            if (index >= 0) {
              _rows.splice(index, 1);
            }

          });
          setSelectedRows([]);
          setRows(_rows);
        }
      });
    }
    else {
      confirmAlert({
        title: t('common.message'),
        message: t('alert.select_at_least_one_record'),
        buttons: [
          {
            label: t('button.ok'),
            onClick: () => { }
          }
        ]
      });
    }
  }

  const onAction = (actionType: ActionType, selectedRows: any[]) => {
    switch (actionType) {
      case ActionType.ADD:

        break;
      case ActionType.EDIT:

        break;
      case ActionType.DELETE:

        break;
      default:
        break;
    }

  }


  return (
    <Container className='mt-5' >

      <Row style={{ marginBottom: 5 }}>
        <Col lg="3">

        </Col>
        {renderif(props.bNew)(
          <Col lg="9" style={{ textAlign: 'right' }}>
            <Button label={t('button.save_changes')} icon="pi pi-save" className="p-button-sm mr-1" onClick={() => saveChanges()} />
            <Button label={t('button.add')} icon="pi pi-plus" className="p-button-sm mr-1" onClick={() => addNew()} />
            <Button label={t('button.delete')} icon="pi pi-trash" className="p-button-sm p-button-danger mr-1" onClick={() => onDelete()} />
            <Button label={t('button.cancel')} icon="pi pi-times" className="p-button-sm p-button-default" onClick={() => props.onHide()} />
          </Col>
        )}
        {renderif(!props.bNew)(
          <Col lg="9" style={{ textAlign: 'right' }}>
            <Button label={t('button.delete')} icon="pi pi-trash" className="p-button-sm p-button-danger mr-1" onClick={() => onDeleteRequest()} />
            <Button label={t('button.cancel')} icon="pi pi-times" className="p-button-sm p-button-default mr-1" onClick={() => props.onHide()} />
          </Col>
        )}
      </Row>
      {renderif(props.bNew)(
        <div className="card">
          <DataTable
            dataKey="stockId"
            value={rows}
            selection={selectedRows}
            onSelectionChange={e => setSelectedRows(e.value)}
            scrollHeight="440px"
            scrollable
          >
            <Column selectionMode="multiple" headerStyle={{ width: '30px' }}></Column>
            {dynamicColumns}

          </DataTable>

        </div>
      )}
      {renderif(!props.bNew)(
        <AppDataTable
          ref={ref}
          title={t('menu.artwork-request')}
          formId={FORM.ART_WORK_REQUEST_DETAIL}
          endpointUrl={ServiceEndPoint.ART_WORK.FETCH_USER_REQUEST_DETAIL}
          payload={payload}
          bAllowActions={false}
          onAction={onAction} />
      )}
      <Dialog header={t('common.stock')} visible={displayBasic} style={{ width: '1000px', height: '568px' }} onHide={() => onHide()}>
        <AddStock onHide={onHide} onSuccess={onSuccess} location={location}></AddStock>
      </Dialog>
      <Toaster />
    </Container>
  );
};
