export const departmentArray = [
  { key: 1, value: 1, label: 'complaints.depHygiene' },
  { key: 2, value: 2, label: 'complaints.depFood' },
  { key: 3, value: 3, label: 'complaints.depAmenities' },
];
//#endregion
export const relationArray = [
  { key: 1, value: 1, label: 'complaints.probSelf' },
  { key: 2, value: 2, label: 'complaints.probRelative' },
];
