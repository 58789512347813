import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ImageUpload } from './image-upload';
import { Dialog } from 'primereact/dialog';
import '../assets/css/style.css';
import { LoginContext } from '../auth';
import { ServiceEndPoint } from '../constants';
import { CommonDataService } from '../services';
import toast, { Toaster } from 'react-hot-toast';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { Panel } from 'primereact/panel';
import { Card } from 'primereact/card';
import { Checkbox } from 'primereact/checkbox';
import renderif from '../constants/renderif';
import { Chips } from 'primereact/chips';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

interface ArtwokProps {
  obj: any;
  onHide: () => void;
  onSuccess: () => void;
}

export const ArtWork = (props: ArtwokProps) => {
  const { state } = useContext(LoginContext);
  const { t } = useTranslation();
  const [file, setFile] = useState<any>();
  const [obj, setObj] = useState<any>(props.obj);
  const [statusList, setStatusList] = useState<any[]>([]);
  const [artistList, setArtistList] = useState<any[]>([]);
  const [techList, setTechList] = useState<any[]>([]);
  const [categoryList, setCategoryList] = useState<any[]>([]);
  const [materialList, setMaterialList] = useState<any[]>([]);
  const [areaList, setAreaList] = useState<any[]>([]);
  const [roomList, setRoomList] = useState<any[]>([]);
  const [imageList, setImageList] = useState<any[]>([]);
  const [selectedImages, setSelectedImages] = useState<any[]>([]);
  const [displayBasic, setDisplayBasic] = useState(false);

  const [allowAddImage, setAllowAddImage] = useState((props.obj.id === "" ? false : true));

  const commonDataService = new CommonDataService();

  const setProperty = (property: any, value: any) => {

    setObj({
      ...obj,
      [property]: value
    });
  };

  const onStatusChange = (e: any) => {
    setProperty("status", e.value);
  }
  const getStatusList = () => {
    commonDataService.executeApiCall(ServiceEndPoint.STATUS.FETCH, { orgId: state.orgID }).then(res => {
      if (res.status) {
        setStatusList([]);
        let rData: any[] = [];
        res.data.forEach((item: any) => {
          rData.push(item);
        });
        setStatusList(rData);
      }
    });
  }
  const onArtistChange = (e: any) => {
    setProperty("artist", e.value);
  }
  const getArtistList = () => {
    commonDataService.executeApiCall(ServiceEndPoint.ARTIST.FETCH, { orgId: state.orgID }).then(res => {
      if (res.status) {
        setArtistList([]);
        let rData: any[] = [];
        res.data.forEach((item: any) => {
          rData.push(item);
        });
        setArtistList(rData);
      }
    });
  }
  const onTechChange = (e: any) => {
    setProperty("technique", e.value);
  }
  const getTechList = () => {
    commonDataService.executeApiCall(ServiceEndPoint.TECHNIQUE.FETCH, { orgId: state.orgID }).then(res => {
      if (res.status) {
        setTechList([]);
        let rData: any[] = [];
        res.data.forEach((item: any) => {
          rData.push(item);
        });
        setTechList(rData);
      }
    });
  }
  const onMaterialChange = (e: any) => {
    setProperty("materials", e.value);
  }
  const getMaterialList = () => {
    commonDataService.executeApiCall(ServiceEndPoint.MATERIAL.FETCH, { orgId: state.orgID }).then(res => {
      if (res.status) {
        setMaterialList([]);
        let rData: any[] = [];
        res.data.forEach((item: any) => {
          rData.push(item);
        });
        setMaterialList(rData);
      }
    });
  }
  const onAreaChange = (e: any) => {
    setProperty("area", e.value);
    getRoomList(e.value);
  }
  const getAreaList = () => {
    commonDataService.executeApiCall(ServiceEndPoint.AREA.FETCH, { orgId: state.orgID }).then(res => {
      if (res.status) {
        setAreaList([]);
        let rData: any[] = [];
        res.data.forEach((item: any) => {
          rData.push(item);
        });
        setAreaList(rData);
      }
    });
  }
  const onRoomChange = (e: any) => {
    setProperty("room", e.value);
  }
  const getRoomList = (areaId: number) => {
    commonDataService.executeApiCall(ServiceEndPoint.AREA_ROOM.FETCH, { orgId: state.orgID }).then(res => {
      if (res.status) {
        setRoomList([]);
        let rData: any[] = [];
        res.data.forEach((item: any) => {
          if (item.areaId === areaId) {
            rData.push(item);
          }
        });
        setRoomList(rData);
      }
    });
  }

  const onCategoryChange = (e: any) => {
    setProperty("category", e.value);
  }
  const getCategoryList = () => {
    commonDataService.executeApiCall(ServiceEndPoint.PRODUCT_CATEGORY.FETCH, { orgId: state.orgID }).then(res => {
      if (res.status) {
        setCategoryList([]);
        let rData: any[] = [];
        res.data.forEach((item: any) => {
          rData.push(item);

        });
        setCategoryList(rData);
      }
    });
  }

  const getImageList = () => {
    commonDataService.executeApiCall(ServiceEndPoint.ART_WORK.FETCH_IMAGES, { pId: obj.id }).then(res => {
      if (res.status) {
        setImageList([]);
        let rData: any[] = [];
        res.data.forEach((item: any) => {
          rData.push(item);
        });
        setImageList(rData);
      }
    });
  }

  useEffect(() => {
    getStatusList();
    getArtistList();
    getTechList();
    getMaterialList();
    getAreaList();
    getCategoryList();
    if (obj) {
      if (obj.area) {
        getRoomList(obj.area);
      }
      getImageList();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps



  const saveChanges = () => {
    if (!obj.title) {
      alert('Please enter title.');
      return;
    }
    confirmDialog({
      message: t('alert.save_message'),
      header: t('alert.confirmation'),
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-primary',
      acceptLabel: t('button.yes'),
      rejectLabel: t('button.no'),
      accept: () => {
        obj.updatedBy = state.userId;
        obj.orgId = state.orgID;
        obj.keywordStr = obj.keyword ? obj.keyword.join(",") : "";
        commonDataService.executeApiCall(ServiceEndPoint.ART_WORK.SAVE, obj)
          .then(res => {
            if (res.status) {
              let obj = res.data;
              obj.keywordStr = obj.keyword != null ? obj.keyword : '';
              obj.keyword = obj.keyword != null ? obj.keyword.split(",") : [];
              setObj(obj);
              setAllowAddImage(true);
              toast.success(t('alert.successfully_saved'), { position: 'top-right', duration: 3000 });

            }
            else {
              toast.error(t('alert.error'), { position: 'top-right', duration: 3000 });
            }
          });
      }
    });
  }

  const onImageChange = (e: any) => {
    let _selectedImages = [...imageList];

    _selectedImages.forEach(item => {
      if (item.id === e.value) {
        item.bChecked = e.checked ? 1 : 0;
      }
    });
    setImageList(_selectedImages);
  }

  const addNew = () => {
    setDisplayBasic(true);
  }

  const onHide = () => {
    setDisplayBasic(false);
  }

  const onSuccess = () => {
    setDisplayBasic(false);
    toast.success(t('alert.successfully_saved'), { position: 'top-right', duration: 3000 });
    getImageList()

  }

  const onImageDelete = () => {
    let selectedImages = imageList.filter((item: any) => { return item.bChecked === 1 });
    if (selectedImages === undefined || selectedImages.length === 0) {
      confirmAlert({
        title: t('common.message'),
        message: t('alert.select_at_least_one_record'),
        buttons: [
          {
            label: t('button.ok'),
            onClick: () => { }
          }
        ]
      });
    }
    else {
      confirmDialog({
        message: t('alert.delete_message'),
        header: t('alert.delete_confirmation'),
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        acceptLabel: t('button.yes'),
        rejectLabel: t('button.no'),
        accept: () => {
          commonDataService.executeApiCall(ServiceEndPoint.ART_WORK.DELETE_IMAGE, { ids: selectedImages.map(item => item.id).join(), userId: state.userId })
            .then(res => {
              console.log(res);
              if (res.status) {
                toast.success(t('alert.record_deleted'), { position: 'top-right', duration: 3000 });
                getImageList();
              }
              else {
                toast.error(t('alert.error'), { position: 'top-right', duration: 3000 });
              }
            });
        }
      });
    }

  }

  const headerTemplate = (options: any) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}>
        <span className={titleClassName}>
          {t('common.images')}
        </span>
        {renderif(allowAddImage)(
          <div>
            <button className="btn pi pi-plus" onClick={addNew}></button>
            <button className="btn pi pi-trash" onClick={onImageDelete}></button>
          </div>
        )}
      </div>
    )
  }


  return (
    <Container className='mt-5' >

      <Row>
        <Col lg={1}></Col>
        <Col lg={10}>
          <Row style={{ marginBottom: 5 }}>
            <Col lg={4}>
              <h5>{t('common.artwork')}</h5>
            </Col>
            <Col lg={8} style={{ textAlign: 'right' }}>
              <Button label={t('button.save_changes')} icon="pi pi-save" className="p-button-sm mr-1" onClick={() => saveChanges()} />
              <Button label={t('button.cancel')} icon="pi pi-times" className="p-button-sm mr-1" onClick={() => props.onHide()} />
            </Col>
          </Row>
          <div className="p-10" style={{ marginBottom: 20 }} >
            <Row>
              <Col lg={8}>
                <Panel header={t('common.details')}>
                  <Row>
                    <Col lg={4}>
                      <p>{t('common.idNo')}</p>
                      <InputText disabled value={obj.idNo} aria-describedby="product-group" onChange={(e) => setProperty("idNo", e.target.value)} style={{ width: '100%' }} className="" />
                    </Col>
                    <Col lg={4}></Col>
                    <Col lg={4}>
                      <p>{t('common.status')}<span className="required">*</span></p>
                      <Dropdown filter value={obj.status} style={{ width: '100%' }} options={statusList} onChange={onStatusChange} optionLabel="statusName" optionValue="id" />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={12}>
                      <p>{t('common.title')}<span className="required">*</span></p>
                      <InputText value={obj.title} aria-describedby="product-group" onChange={(e) => setProperty("title", e.target.value)} style={{ width: '100%' }} className="" />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <p>{t('common.keyword')}</p>
                      <Chips style={{ width: '100%' }} max={10} value={obj.keyword} onChange={(e) => setProperty("keyword", e.target.value)} separator="," />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <p>{t('common.artist')}</p>
                      <Dropdown filter value={obj.artist} style={{ width: '100%' }} options={artistList} onChange={onArtistChange} optionLabel="name" optionValue="id" />
                    </Col>
                    <Col lg={6}>
                      <p>{t('common.signature')}</p>
                      <InputText value={obj.signature} aria-describedby="product-group" onChange={(e) => setProperty("signature", e.target.value)} style={{ width: '100%' }} className="" />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <p>{t('common.description')}</p>
                      <InputTextarea rows={3} value={obj.description} aria-describedby="product-group" onChange={(e) => setProperty("description", e.target.value)} style={{ width: '100%' }} className="" />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <p>{t('common.category')}</p>
                      <Dropdown filter value={obj.category} style={{ width: '100%' }} options={categoryList} onChange={onCategoryChange} optionLabel="category" optionValue="id" />
                    </Col>
                    <Col lg={4}>
                      <p>{t('common.technique')}</p>
                      <Dropdown filter value={obj.technique} style={{ width: '100%' }} options={techList} onChange={onTechChange} optionLabel="name" optionValue="id" />
                    </Col>
                    <Col lg={4}>
                      <p>{t('common.material')}</p>
                      <Dropdown filter value={obj.materials} style={{ width: '100%' }} options={materialList} onChange={onMaterialChange} optionLabel="name" optionValue="id" />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <p>{t('common.height')}</p>
                      <InputText value={obj.height} aria-describedby="product-group" onChange={(e) => setProperty("height", e.target.value)} style={{ width: '100%' }} className="" />
                    </Col>
                    <Col lg={4}>
                      <p>{t('common.width')}</p>
                      <InputText value={obj.width} aria-describedby="product-group" onChange={(e) => setProperty("width", e.target.value)} style={{ width: '100%' }} className="" />
                    </Col>
                    <Col lg={4}>
                      <p>{t('common.depth')}</p>
                      <InputText value={obj.depth} aria-describedby="product-group" onChange={(e) => setProperty("depth", e.target.value)} style={{ width: '100%' }} className="" />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <p>{t('common.area')}</p>
                      <Dropdown filter value={obj.area} style={{ width: '100%' }} options={areaList} onChange={onAreaChange} optionLabel="name" optionValue="id" />
                    </Col>
                    <Col lg={6}>
                      <p>{t('common.room')}</p>
                      <Dropdown filter value={obj.room} style={{ width: '100%' }} options={roomList} onChange={onRoomChange} optionLabel="name" optionValue="id" />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3}>
                      <p>{t('common.purchasedate')}</p>
                      <Calendar dateFormat="yy-mm-dd" value={obj.purchasedate} aria-describedby="product-group" onChange={(e) => setProperty("purchasedate", e.value)} style={{ width: '100%' }} className="" />
                    </Col>
                    <Col lg={3}>
                      <p>{t('common.purchaseprice')}</p>
                      <InputText value={obj.purchaseprice} aria-describedby="product-group" onChange={(e) => setProperty("purchaseprice", e.target.value)} style={{ width: '100%' }} className="" />
                    </Col>
                    <Col lg={3}>
                      <p>{t('common.purchasefrom')}</p>
                      <InputText value={obj.purchase_from} aria-describedby="product-group" onChange={(e) => setProperty("purchase_from", e.target.value)} style={{ width: '100%' }} className="" />
                    </Col>
                    <Col lg={3}>
                      <p>{t('common.adventure')}</p>
                      <InputText value={obj.adventure} aria-describedby="product-group" onChange={(e) => setProperty("adventure", e.target.value)} style={{ width: '100%' }} className="" />
                    </Col>
                  </Row>
                </Panel>
              </Col>

              <Col lg={4} style={{ textAlign: 'center' }}>
                <Dialog header={t('common.image_upload')} visible={displayBasic} style={{ width: '30vw' }} onHide={() => onHide()}>
                  <ImageUpload onHide={onHide} onSuccess={onSuccess} stockId={obj.id}></ImageUpload>
                </Dialog>
                <Panel headerTemplate={headerTemplate} header={t('common.images')}>
                  <Row>
                    {imageList.map(item => {
                      return (
                        <Col lg={6} key={item.id}>
                          <Card>
                            <Checkbox value={item.id} onChange={onImageChange} checked={item.bChecked === 1} style={{ position: 'absolute', top: 5, left: 20 }} />
                            <img src={`${process.env.REACT_APP_API_URL}/api${item.imageUrl}`} style={{ width: 100, height: 100 }} />
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                </Panel>
              </Col>

            </Row>
          </div>
        </Col>
        <Col lg={1}></Col>
      </Row>
      <Toaster />
    </Container>
  );
};
