import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button, Row, Col, Alert } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { axiosInstance } from '../../lib';

/**
 * @description Component Registers User to the system
 * @output Renders input fields that take in data required for registration,
 * saves them to state, and calls back-end API to register the user.
 */

export function EditProfile() {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('-');
  const [accessibility, setAccessibility] = useState<Array<number>>([]);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validated, setValidated] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');

  useEffect(() => {
    if (password !== confirmPassword) {
      setValidationMessage('Passwords do not match');
    } else setValidationMessage('');
  }, [password, confirmPassword]);

  const checkBoxToggle = (target: any) => {
    const { value, checked } = target;
    if (checked) {
      setAccessibility([...accessibility, parseInt(value, 10)]);
    } else {
      setAccessibility(
        accessibility.filter((element) => element !== parseInt(value, 10))
      );
    }
    console.log(parseInt(value, 10), { checked });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      axiosInstance({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/v1/create-user-account`,
        data: {
          name,
          email,
          gender,
          accessibility,
          password,
          type: 1,
          role: 1,
          isVerified: true,
          isActive: true,
        },
      });
    }
    setValidated(true);
  };
  return (
    <div
      style={{
        margin: '50px auto 50px',
        maxWidth: '320px',
        backgroundColor: '#E7E7E7',
        padding: '35px 30px',
        borderRadius: '20px',
      }}
    >
      <h5 className='font-weight-bold'>Edit Profile</h5>
      <br />
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group controlId='name'>
          <Form.Label className='font-weight-bold'>
            {t('common.fullName')}
          </Form.Label>
          <Form.Control
            required
            type='name'
            placeholder={t('common.fullNamePlaceholder')}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {/* validation message */}
          <Form.Control.Feedback type='invalid'>
            Name is required.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId='email'>
          <Form.Label className='font-weight-bold'>
            {t('common.email')}
          </Form.Label>
          <Form.Control
            required
            type='email'
            placeholder={t('common.emailPlaceholder')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Form.Text className='text-muted'>
            {t('auth.registrationText')}
          </Form.Text>
          {/* validation message */}
          <Form.Control.Feedback type='invalid'>
            Please enter a valid email address.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId='gender'>
          <Form.Label className='font-weight-bold'>
            {t('common.gender')}
          </Form.Label>
          <Form.Control
            required
            as='select'
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            custom
          >
            <option label='Select Gender'></option>
            <option value='Male'>{t('common.genderMale')}</option>
            <option value='Female'>{t('common.genderFemale')}</option>
          </Form.Control>
          {/* validation message */}
          <Form.Control.Feedback type='invalid'>
            Please select a gender.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId='accessibility'>
          <Form.Label className='font-weight-bold'>
            {t('common.accessibility')}
          </Form.Label>
          <Row>
            <Col>
              <Form.Check
                inline
                label={t('auth.eyeSight')}
                type='checkbox'
                id='inline-checkbox-1'
                value={1}
                onClick={(e: any) => checkBoxToggle(e.target)}
              />
            </Col>
            <Col>
              <Form.Check
                inline
                label={t('auth.hearing')}
                type='checkbox'
                id='inline-checkbox-2'
                value={2}
                onClick={(e: any) => checkBoxToggle(e.target)}
              />
            </Col>
            <Col>
              <Form.Check
                inline
                label={t('auth.dementia')}
                type='checkbox'
                id='inline-checkbox-3'
                value={3}
                onClick={(e: any) => checkBoxToggle(e.target)}
              />
            </Col>
          </Row>
        </Form.Group>

        <Form.Group controlId='password'>
          <Form.Label className='font-weight-bold'>
            {t('common.password')}
          </Form.Label>
          <Form.Control
            required
            minLength={8}
            maxLength={16}
            type='password'
            placeholder={t('common.passwordPlaceholder')}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {/* validation message */}
          <Form.Control.Feedback type='invalid'>
            Password must be 8-16 characters long.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId='confirmPassword'>
          <Form.Label className='font-weight-bold'>
            {t('common.confirmPassword')}
          </Form.Label>
          <Form.Control
            required
            type='password'
            placeholder={t('common.confirmPasswordPlaceholder')}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {/* validation message */}
          <Form.Control.Feedback type='invalid'>
            Please confirm your password
          </Form.Control.Feedback>
          {validationMessage !== '' ? (
            <Alert className='mt-1' variant='warning'>
              {validationMessage}
            </Alert>
          ) : null}
        </Form.Group>

        <Button variant='dark' type='submit'>
          {t('button.register')}
        </Button>

        <Row>
          <Col className='text-right pt-5'>
            <span>Already have an account? </span>
            <NavLink to='/'>{t('button.login')}</NavLink>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
