import { axiosInstance } from '../lib';

export class CommonDataService {

    public login(data: any) {
        return axiosInstance
            .post(
                `${process.env.REACT_APP_LOGIN_URL}/api/user/login-otp`, data
            )
            .then((res) => res.data);
    }

    public verifyLoginOTP(data: any) {
        return axiosInstance
            .post(
                `${process.env.REACT_APP_LOGIN_URL}/api/user/verify-login-otp`, data
            )
            .then((res) => res.data);
    }

    public register(data: any) {
        return axiosInstance
            .post(
                `${process.env.REACT_APP_LOGIN_URL}/api/user/register`, data
            )
            .then((res) => res.data);
    }
    public sendOTP(data: any) {
        return axiosInstance
            .post(
                `${process.env.REACT_APP_LOGIN_URL}/api/user/send-otp`, data
            )
            .then((res) => res.data);
    }
    public verifyOTP(data: any) {
        return axiosInstance
            .post(
                `${process.env.REACT_APP_LOGIN_URL}/api/user/verify-otp`, data
            )
            .then((res) => res.data);
    }
    public changePassword(data: any) {
        return axiosInstance
            .post(
                `${process.env.REACT_APP_LOGIN_URL}/api/user/change-password`, data
            )
            .then((res) => res.data);
    }

    public executeApiCall(path: String, data: any) {
        return axiosInstance
            .post(
                `${process.env.REACT_APP_API_URL}/api${path}`, data
            )
            .then((res) => res.data);
    }
}