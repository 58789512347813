import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

export const Settings = () => {
  const { t } = useTranslation();
  return (
    <Container className='settings-page'>
      <Row className='p-2 pb-3'>
        <Col className='font-weight-bold'>{t('common.settings')}</Col>
      </Row>
      <Row className=' p-2'>
        <NavLink to='/editProfile'>
          <Col>{t('profile.editProfile')}</Col>
        </NavLink>
      </Row>
      <Row className='border-top border-bottom p-2'>
        <NavLink to='/changePassword'>
          <Col>{t('button.changePassword')}</Col>
        </NavLink>
      </Row>
    </Container>
  );
};
