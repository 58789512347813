import React, { useContext, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LoginContext } from '../auth';
import { ServiceEndPoint } from '../constants';
import { Button } from 'primereact/button';
import toast, { Toaster } from 'react-hot-toast';
//import { v4 as uuidv4 } from 'uuid';

import '../assets/css/style.css';
import { CommonDataService } from '../services';

interface ImageUploadProps {
    stockId: string;
    onHide: () => void;
    onSuccess: () => void;
}

export const ImageUpload = (props: ImageUploadProps) => {
    const { state } = useContext(LoginContext);
    const [id, setId] = useState(props.stockId);
    const [file, setFile] = useState<any>();

    const { t } = useTranslation();
    const commonDataService = new CommonDataService();

    const handleFile = async (event: any) => {
        const base64 = await convertBase64(event.target.files[0]);
        setFile(base64);

    }

    const convertBase64 = (file: any) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }


    const save = () => {
        commonDataService.executeApiCall(ServiceEndPoint.ART_WORK.SAVE_IMAGE,
            {
                orgId: state.orgID,
                userId: state.userId,
                stockId: id,
                image: file
            })
            .then(res => {
                if (res.status) {
                    if (res.data > 0) {
                        props.onSuccess();
                    }
                    else if (res.data === -1) {
                        toast.error(t('alert.duplicate_record'), { position: 'top-right', duration: 3000 });
                    }
                }
            });
    }



    return (
        <div style={{ marginTop: 10, textAlign:'center' }}>
            <img src={file} style={{ width: 150, height: 150, marginBottom: 5 }} />
            <div className="inputWrapper">
                <input className="fileInput" type="file" name="file1" onChange={handleFile} />
                Browse
            </div>
            <div style={{ width: '100%', marginTop: 15, textAlign: 'right' }}>
                <Button label={t('button.cancel')} onClick={() => props.onHide()} className="p-button-text" />
                <Button label={t('button.submit')} onClick={() => save()} autoFocus />
            </div>
            <Toaster />
        </div>
    );
}