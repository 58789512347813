import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import { EditProfile, Profile, Settings } from './profile';
import {
  LoginForm,
  ForgotPassword,
  ChangePassword,
  ResetPassword,
  LoginContextProvider,
  RegistrationForm,
  LoginContainer,
} from './auth';
import { Header, SideBar } from './nav';
import { ToggleLocale } from './nav/components/toggle-locale';
import { Col } from 'react-bootstrap';
import { ComplaintForm } from './complaints';
import { ProductGroupList } from './master-setup/product-group/product-group-list';
import { StatusList } from './master-setup/status-list/status-list';
import { ProductCategoryList } from './master-setup/product-category/product-category-list';
import { ArtistList } from './master-setup/artist/artist-list';
import { TechniqueList } from './master-setup/technique/technique-list';
import { MaterialList } from './master-setup/material/material-list';
import { AreaList } from './master-setup/area/area-list';
import { AreaRoomList } from './master-setup/area-room/area-room-list';
import { Account } from './account/account';
import { ArtWorkList } from './art-work/art-work-list';
import { ArtWorkRequestList } from './art-work/art-work-request-list';
import { ArtWorkReceiveList } from './art-work/art-work-receive-list';
import { Dashboard } from './dashboard/dashboard';
import { PreferedLocation } from './master-setup/prefered-location/prefered-location';
import { PreferedLocationList } from './master-setup/prefered-location/prefered-location-list';
import { ArtWorkReport } from './report/report';
import { ArtWorkRequestUserList } from './art-work/art-work-request-list-user';


// page uses the hook
function LoggedInPageAdmin() {
  return (
    <>
      <Header />
      {/* <SideBar /> */}
      <div className='App' data-testid='logged-in-app'>
        <div className='AppContainer'>
          <Col md={{ span: 12 }} className='' style={{marginTop:10}}>
            <Switch>
              <Route exact path='/' component={Dashboard} />

              <Route path='/product-group' component={ProductGroupList} />
              <Route path='/status' component={StatusList} />
              <Route path='/product-category' component={ProductCategoryList} />
              <Route path='/artist' component={ArtistList} /> 
              <Route path='/technique' component={TechniqueList} /> 
              <Route path='/material' component={MaterialList} /> 
              <Route path='/area' component={AreaList} /> 
              <Route path='/area-room' component={AreaRoomList} /> 
              <Route path='/account' component={Account} />
              <Route path='/art-work' component={ArtWorkList} />
              <Route path='/art-work-request' component={ArtWorkRequestList} />
              <Route path='/art-work-receive' component={ArtWorkReceiveList} />
              <Route path='/settings' component={Settings} />
              <Route exact path='/editProfile' component={EditProfile} />
              <Route path='/changePassword' component={ChangePassword} />
              <Route exact path='/complaints' component={ComplaintForm} />
              <Route path='/prefered-location' component={PreferedLocationList}/>
              <Route path='/report' component={ArtWorkReport}/>
            </Switch>
          </Col>
        </div>
      </div>
    </>
  );
}

function LoggedInPageReception() {
  return (
    <>
      <Header />
      {/* <SideBar /> */}
      <div className='App' data-testid='logged-in-app'>
        <div className='AppContainer'>
          <Col md={{ span: 12 }} className='' style={{marginTop:10}}>
            <Switch>
              <Route exact path='/' component={ArtWorkReceiveList} />
            </Switch>
          </Col>
        </div>
      </div>
    </>
  );
}

function LoggedInPageEmployee() {
  return (
    <>
      <Header />
      {/* <SideBar /> */}
      <div className='App' data-testid='logged-in-app'>
        <div className='AppContainer'>
          <Col md={{ span: 12 }} className='' style={{marginTop:10}}>
            <Switch>
              <Route exact path='/' component={ArtWorkRequestUserList} />
            </Switch>
          </Col>
        </div>
      </div>
    </>
  );
}

function LoggedOutPage() {
  return (
    <>
      <div className='App' data-testid='logged-out-app'>
        <div className='AppContainer'>
          {/* <Col
            className='mt-3'
            xs={{ span: 6, offset: 6 }}
            md={{ span: 2, offset: 6 }}
          >
            <ToggleLocale />
          </Col> */}
          <Switch>
            <Route exact path='/' component={LoginForm} />
            <Route path='/register' component={RegistrationForm} />
            <Route path='/forgotPassword' component={ForgotPassword} />
            <Route path='/resetPassword' component={ResetPassword} />
          </Switch>
        </div>
      </div>
    </>
  );
}

// loading component for suspense fallback
const Loader = () => (
  <div className='App'>
    <div>loading...</div>
  </div>
);

// here app catches the suspense from page in case translations are not yet loaded
export default function App() {
  return (
    
    <Suspense fallback={<Loader />}>
      <LoginContextProvider>
        <Router>
          <LoginContainer
            isLoggedIn={<LoggedInPageAdmin />}
            willLogIn={<LoggedOutPage />}
            receptionLogin = {<LoggedInPageReception/>}
            employeeLogin = {<LoggedInPageEmployee/>}
          />
        </Router>
      </LoginContextProvider>
    </Suspense>
  );
}
