import ReactDOM from 'react-dom';
import React, { useContext, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { LoginContext } from '../../auth';
import { AppDataTable } from '../../common/components/table';
import { ServiceEndPoint } from '../../constants';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import toast, { Toaster } from 'react-hot-toast';

import '../../assets/css/style.css';
import { CommonDataService } from '../../services';

interface ProductGroupProps {
    groupId: number;
    groupName: string;
    onHide: () => void;
    onSuccess: () => void;
}

export const ProductGroup = (props: ProductGroupProps) => {
    const { state } = useContext(LoginContext);
    const [id, setId] = useState(props.groupId);
    const [value, setValue] = useState(props.groupName);

    const { t } = useTranslation();
    const commonDataService = new CommonDataService();
   
    const saveProductGroup = ()=>{
        commonDataService.executeApiCall(ServiceEndPoint.PRODUCT_GROUP.SAVE_PRODUCT_GROUP, 
            {
                orgId: state.orgID,
                userId: state.userId,
                groupId: id,
                groupName: value
            })
        .then(res=>{
            if(res.status){
                if(res.data > 0){
                    props.onSuccess();
                }
                else if(res.data === -1){
                    toast.error(t('alert.duplicate_record'),{position:'top-right', duration:3000});
                }
            }
        });
    }

   

    return (
        <div style={{marginTop:10}}>
            <p>{t('common.groupName')}<span className="required">*</span></p>
            <InputText value={value} aria-describedby="product-group" onChange={(e) => setValue(e.target.value)} style={{ width: '100%' }}className=""/>
            
            <div style={{width: '100%', marginTop: 15, textAlign:'right'}}>
                <Button label={t('button.cancel')} onClick={() => props.onHide()} className="p-button-text" />
                <Button label={t('button.submit')} onClick={() => saveProductGroup()} autoFocus />
            </div>
            <Toaster />
        </div>
    );
}