import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button, Alert } from 'react-bootstrap';
import { axiosInstance } from '../../lib';

/**
 * @description Component resets forgotten password for user.
 * @output Renders two input fields that take in New Password && Confirm Password,
 * saves them to state, and if both inputs match, calls back-end API to change the password
 */

export function ResetPassword() {
  const { t } = useTranslation();
  const [hash, setHash] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validated, setValidated] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');

  useEffect(() => {
    if (newPassword !== confirmPassword) {
      setValidationMessage('Passwords do not match');
    } else setValidationMessage('');
  }, [newPassword, confirmPassword]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setHash(`${process.env.REACT_APP_RESET_PASSWORD_HASH}`);
      axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/password/reset?hash=${hash}&newPassword=${newPassword}`
      );
    }
    setValidated(true);
  };

  return (
    <div
      style={{
        margin: '50px auto 50px',
        maxWidth: '320px',
        backgroundColor: '#E7E7E7',
        padding: '35px 30px',
        borderRadius: '20px',
      }}
    >
      <h5>{t('auth.resetPassword')}</h5>
      <br />
      <Form
        noValidate
        validated={validated}
        data-testid='reset-password-form'
        onSubmit={handleSubmit}
      >
        <Form.Group controlId='newPassword'>
          <Form.Label data-testid='new-password-label'>
            {t('auth.newPassword')}
          </Form.Label>
          <Form.Control
            data-testid='new-password-input'
            type='password'
            required
            minLength={8}
            maxLength={16}
            placeholder={t('auth.newPasswordPlaceholder')}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          {/* validation message */}
          <Form.Control.Feedback type='invalid'>
            Password must be 8-16 characters long.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId='confirmPassword'>
          <Form.Label data-testid='conf-password-label'>
            {t('auth.confirmNewPassword')}
          </Form.Label>
          <Form.Control
            data-testid='conf-password-input'
            type='password'
            required
            placeholder={t('auth.confirmNewPasswordPlaceholder')}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {/* validation message */}
          <Form.Control.Feedback type='invalid'>
            Please confirm your password
          </Form.Control.Feedback>
          {validationMessage !== '' ? (
            <Alert className='mt-1' variant='warning'>
              {validationMessage}
            </Alert>
          ) : null}
        </Form.Group>

        <Button variant='dark' type='submit' data-testid='button'>
          {t('auth.resetPassword')}
        </Button>
      </Form>
    </div>
  );
}
