import React, { useContext, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LoginContext } from '../auth';
import { AppDataTable } from '../common/components/table';
import { ServiceEndPoint } from '../constants';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { confirmDialog } from 'primereact/confirmdialog';
import '../assets/css/style.css';
import { ActionType, FORM } from '../constants/enum';
import toast, { Toaster } from 'react-hot-toast';
import { CommonDataService } from '../services';
import renderif from '../constants/renderif';
import { ArtWork } from './art-work';

export const ArtWorkList = () => {
    const { state } = useContext(LoginContext);
    const ref = useRef<any>(null);
    const { t } = useTranslation();
    const [payload, setPayLoad] = useState(JSON.stringify({ orgId: state.orgID, areaId:'',roomId:'' }));
    const [displayBasic, setDisplayBasic] = useState(false);
    const [selectedObject, setSelectedObject] = useState({});
    const [isForm, setIsForm] = useState(false);

    const commonDataService = new CommonDataService();
    const onAction = (actionType: ActionType, selectedRows: any[]) => {
        switch (actionType) {
            case ActionType.ADD:
                addNew();
                break;
            case ActionType.EDIT:
                edit(selectedRows);
                break;
            case ActionType.DELETE:
                deleteConfirm(selectedRows);
                break;
            default:
                break;
        }

    }

    const addNew = () => {
        setSelectedObject({ id: '',
        orgId: state.orgID,
        idNo: '',
        title: '',
        artist: -1,
        signature: '',
        description: '',
        technique: -1,
        materials: -1,
        height: '',
        width: '',
        depth: '',
        area: 40,
        room: 259,
        purchasedate: '',
        purchaseprice: 0,
        purchase_from: '',
        status: 1,
        adventure: '',
        keyword: [],
        updatedBy: ''  });
        //setDisplayBasic(true);
        setIsForm(true);
    }

    const edit = (selectedRows: any[]) => {
        
        debugger;

        let obj = selectedRows[0];
        obj.keywordStr = obj.keyword !=null ?obj.keyword:'';
        obj.keyword = obj.keyword !=null ? obj.keyword.split(","):[];

        setSelectedObject(obj);
        setIsForm(true);
    }

    const onHide = () => {
        ref.current.clearSelectedRows();
        setIsForm(false);
    }

    const onSuccess = () => {
        setDisplayBasic(false);
        toast.success(t('alert.successfully_saved'), { position: 'top-right', duration: 3000 });
        ref.current.updateMetaData();

        setPayLoad(JSON.stringify({ orgId: state.orgID }));
    }

    const deleteConfirm = (selectedRows: any[]) => {
        confirmDialog({
            message: t('alert.delete_message'),
            header: t('alert.delete_confirmation'),
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: t('button.yes'),
            rejectLabel: t('button.no'),
            accept: () => {
                commonDataService.executeApiCall(ServiceEndPoint.ART_WORK.DELETE, { ids: selectedRows.map(item => item.id).join(), userId: state.userId })
                    .then(res => {
                        console.log(res);
                        if (res.status) {
                            toast.success(t('alert.record_deleted'), { position: 'top-right', duration: 3000 });
                            ref.current.updateMetaData();
                        }
                        else {
                            toast.error(t('alert.error'), { position: 'top-right', duration: 3000 });
                        }
                    });
            }
        });
    };


    return (
        <Container>
            {/* {renderif(isForm === false)( */}
                <div style={{display: isForm === false? 'block': 'none'}}>
                <AppDataTable
                    ref={ref}
                    title={t('menu.artwork')}
                    formId={FORM.ART_WORK}
                    endpointUrl={ServiceEndPoint.ART_WORK.FETCH}
                    payload={payload}
                    onAction={onAction} />
                    </div>
            {/* )} */}
            {renderif(isForm === true)(
                <ArtWork onHide={onHide} onSuccess={onSuccess} obj={selectedObject}></ArtWork>
            )}
            {/* <Dialog header={t('menu.mastersetup.product_category')} visible={displayBasic} style={{ width: '30vw' }}  onHide={() => onHide()}>
                    <ProductCategory onHide={onHide} onSuccess={onSuccess} groupId={selectedObject.groupId} id={selectedObject.id} name={selectedObject.name}></ProductCategory>
                </Dialog> */}
            <Toaster />
        </Container>

    );
}