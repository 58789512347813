import React, { useState, useEffect, useImperativeHandle, forwardRef, useRef, createRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Ripple } from 'primereact/ripple';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';
import { CommonDataService } from '../../services';
import { ServiceEndPoint } from '../../constants';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';
import '../../assets/css/table.css';
import { constants } from 'buffer';
import { Row, Col } from 'react-bootstrap';
import { ActionType } from '../../constants/enum';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import renderif from '../../constants/renderif';
import { ImageViewer } from './image-viewer';
import { Galleria, GalleriaProps } from 'primereact/galleria';

// import { saveAs } from 'file-saver';

interface tableProps {
    title: string;
    formId: number;
    endpointUrl: string;
    payload: string;
    bAllowActions?: Boolean;
    onAction: (actionId: ActionType, selectedRows: any[]) => void;
}

export const AppDataTable = forwardRef((props: tableProps, ref) => {
    const [_title, setTitle] = useState(props.title);
    const [_formId, setFormId] = useState(props.formId);
    const [_payload, setPayLoad] = useState(props.payload);
    const [allowActions, setAllowActions] = useState((props.bAllowActions !== undefined ? props.bAllowActions : true));
    const [page, setPage] = useState(1);
    const [cols, setCols] = useState<any[]>([]);
    const [rows, setRows] = useState<any[]>([]);
    const [customers3, setCustomers3] = useState([]);
    const [first1, setFirst1] = useState(0);
    const [rows1, setRows1] = useState(10);
    const [first2, setFirst2] = useState(0);
    const [rows2, setRows2] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageInputTooltip, setPageInputTooltip] = useState('Press \'Enter\' key to go to this page.');
    const [selectedRows, setSelectedRows] = useState<any[]>();

    const [imageList, setImageList] = useState<any[]>([]);
    const [displayImag, setDisplayImage] = useState(false);

    const [activeIndex, setActiveIndex] = useState(0);

    const dt = useRef<any>(null);

    var FileSaver = require('file-saver');

    var jsPDF = require('jspdf');
require('jspdf-autotable');

    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 5
        },
        {
            breakpoint: '768px',
            numVisible: 3
        },
        {
            breakpoint: '560px',
            numVisible: 1
        }
    ];

    const [renderIndex, setRenderIndex] = useState(1);

    const commonDataService = new CommonDataService();
    const { t } = useTranslation();
    const toast = useRef();

    const galleria1 = React.createRef<Galleria>();



    const onNew = () => {
        props.onAction(ActionType.ADD, []);
    }

    const onEdit = () => {
        debugger;
        if (selectedRows === undefined || selectedRows.length === 0) {
            confirmAlert({
                title: t('common.message'),
                message: t('alert.select_at_least_one_record'),
                buttons: [
                    {
                        label: t('button.ok'),
                        onClick: () => { }
                    }
                ]
            });
        }
        else if (selectedRows !== undefined && selectedRows.length > 1) {
            confirmAlert({
                title: t('common.message'),
                message: t('alert.select_only_one_record'),
                buttons: [
                    {
                        label: t('button.ok'),
                        onClick: () => { }
                    }
                ]
            });
        }
        else {
            props.onAction(ActionType.EDIT, selectedRows);
        }

    }

    const onDelete = () => {
        debugger;
        if (selectedRows === undefined || selectedRows.length === 0) {
            confirmAlert({
                title: t('common.message'),
                message: t('alert.select_at_least_one_record'),
                buttons: [
                    {
                        label: t('button.ok'),
                        onClick: () => { }
                    }
                ]
            });
        }
        else {
            props.onAction(ActionType.DELETE, selectedRows);
        }

    }

    const onCustomPage1 = (event: any) => {
        setFirst1(event.first);
        setRows1(event.rows);
        setCurrentPage(event.page + 1);
    }

    const onPageInputKeyDown = (event: any, options: any) => {
        if (event.key === 'Enter') {
            const page: number = (currentPage);
            if (page < 0 || page > options.totalPages) {
                setPageInputTooltip(`Value must be between 1 and ${options.totalPages}.`);
            }
            else {
                const first = currentPage ? options.rows * (page - 1) : 0;

                setFirst1(first);
                setPageInputTooltip('Press \'Enter\' key to go to this page.');
            }
        }
    }

    const onPageInputChange = (event: any) => {
        setCurrentPage(event.target.value);
    }

    async function getFormMetaData() {
        commonDataService.executeApiCall(ServiceEndPoint.FORM_META_DATA.FETCH, { formId: _formId }).then(res => {

            if (res.status) {
                setCols([]);
                let ary = [];
                for (var i = 0; i < res.data.length; i++) {
                    ary.push(res.data[i]);
                }
                setCols(ary);


            }
        });
    }

    async function getFormData(payload: string = "") {
        debugger;
        
        if (payload !== '') {
            commonDataService.executeApiCall(props.endpointUrl, JSON.parse(payload)).then(res => {

                if (res.status) {
                    setRows([]);
                    let rData: any[] = [];
                    res.data.forEach((item: any) => {
                        rData.push(item);
                    });
                    setRows(rData);
                }
            });
        }
    }

    const getSelectedRows = () => {
        return selectedRows;
    }

    const clearSelectedRows = () => {
        setSelectedRows([]);
    }

    useImperativeHandle(ref, () => {
        return {
            updateMetaData: updateMetaData,
            getSelectedRows: getSelectedRows,
            exportCSV: exportCSV,
            exportExcel: exportExcel,
            exportPdf: exportPdf,
            updatePayLoad: updatePayLoad,
            clearSelectedRows: clearSelectedRows
        }
    });
    function updatePayLoad(pData: any){
        debugger;
        setPayLoad(pData);
    }
    function updateMetaData(payload: any = "") {
        setSelectedRows([]);
        getFormMetaData();
        getFormData((!payload?_payload:payload));
    }
    useEffect(() => {
        getFormMetaData();
        getFormData(_payload);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const viewImages = (selectedImage: string, colName: string) => {
        let _imageList: any[] = [];
        let list = [];

        rows.forEach((item: any) => {
            debugger;
            if (item[colName] != '' && item[colName] === selectedImage) {
                if (item["images"]) {
                    list = item["images"].split(",");
                    list.forEach((element: any) => {
                        _imageList.push({
                            image: element.replace("/40/40", "/2000/2000"),
                            thumbnail: element.replace("/40/40", "/100/100"),
                            bSelected: item[colName] === selectedImage ? true : false
                        });
                    });

                    if (list.length === 0) {
                        _imageList.push({
                            image: item[colName].replace("/40/40", "/2000/2000"),
                            thumbnail: item[colName].replace("/40/40", "/100/100"),
                            bSelected: item[colName] === selectedImage ? true : false
                        });
                    }
                }
                else {
                    _imageList.push({
                        image: item[colName].replace("/40/40", "/2000/2000"),
                        thumbnail: item[colName].replace("/40/40", "/100/100"),
                        bSelected: item[colName] === selectedImage ? true : false
                    });
                }
            }
        });

        // let index = _imageList.findIndex(item=> item.bSelected === true);
        // setActiveIndex(index);

        setImageList(_imageList);
        setDisplayImage(true);
        // if(galleria1.current){
        // galleria1.current.show();
        // }
    }

    const renderDate = (rowData: any, column: any) => {
        let col = cols.find(a => a.colName === column.field);
        if (rowData[column.field] !== '') {
            return <span>{Moment(rowData[column.field]).format(col.colFormat)}</span>;
        }
        else { return ''; }
    }

    const renderImage = (rowData: any, column: any) => {
        if (rowData[column.field] !== '') {
            return <img onClick={() => viewImages(rowData[column.field], column.field)} src={`${process.env.REACT_APP_API_URL}/api${rowData[column.field]}`} style={{ width: 40, height: 40 }} />
        }
        return '';
    }
    const renderChecbox = (rowData: any, column: any) => {
        if (rowData[column.field] !== '') {
            return <Checkbox inputId="cb1" value={0} disabled={true} checked={rowData[column.field].toString() === '1'} />
        }
        return '';
    }

    const renderText = (rowData: any, column: any) => {
        let col = cols.find(a => a.colName === column.field);
        if (rowData[column.field] && col.colTranslate === 1) {
            return <span>{t(rowData[column.field])}</span>;
        }
        else {
            return <span>{(rowData[column.field])}</span>;
        }
        return '';
    }

    const dynamicColumns = cols.map((col, i) => {
        if (col.colType === 2) {
            return <Column key={i} field={col.colName} header={t("common." + col.headerText)} filter filterMatchMode="contains" sortable={true} headerStyle={{ width: col.colWidth === 0 ? '100%' : col.colWidth.toString() + 'px' }} />;
        }
        else if (col.colType === 3) {
            return <Column key={i} field={col.colName} body={renderDate} header={t("common." + col.headerText)} filter filterMatchMode="contains" sortable={true} headerStyle={{ width: col.colWidth === 0 ? '100%' : col.colWidth.toString() + 'px' }} />;
        }
        else if (col.colType === 4) {
            return <Column key={i} field={col.colName} body={renderImage} header={t("common." + col.headerText)} filter filterMatchMode="contains" sortable={true} headerStyle={{ width: col.colWidth === 0 ? '100%' : col.colWidth.toString() + 'px' }} />;
        }
        else if (col.colType === 5) {
            return <Column key={i} field={col.colName} body={renderChecbox} header={t("common." + col.headerText)} filter filterMatchMode="contains" sortable={true} headerStyle={{ width: col.colWidth === 0 ? '100%' : col.colWidth.toString() + 'px' }} />;
        }
        return <Column key={i} body={renderText} field={col.colName} header={t("common." + col.headerText)} filter filterMatchMode="contains" sortable={true} headerStyle={{ width: col.colWidth === 0 ? '100%' : col.colWidth.toString() + 'px' }} />;
    });

    const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
    const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;
    const template1 = {
        layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
        'PrevPageLink': (options: any) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-p-3">Previous</span>
                    <Ripple />
                </button>
            )
        },
        'NextPageLink': (options: any) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick()} disabled={options.disabled}>
                    <span className="p-p-3">Next</span>
                    <Ripple />
                </button>
            )
        },
        'PageLinks': (options: any) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });

                return <span className={className} style={{ userSelect: 'none' }}>...</span>;
            }

            return (
                <button type="button" className={options.className} onClick={options.onClick}>
                    {options.page + 1}
                    <Ripple />
                </button>
            )
        },
        'RowsPerPageDropdown': (options: any) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 'All', value: options.totalRecords }
            ];

            return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} appendTo={document.body} />;
        },
        'CurrentPageReport': (options: any) => {
            return (
                <span className="p-mx-3" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
                    Go to <InputText className="p-ml-1" value={currentPage} tooltip={pageInputTooltip}
                        onKeyDown={(e) => onPageInputKeyDown(e, options)} onChange={onPageInputChange} />
                </span>
            )
        }
    };
    const template2 = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        'RowsPerPageDropdown': (options: any) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 }
            ];

            return (
                <>
                    <span className="p-mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Items per page: </span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} appendTo={document.body} />
                </>
            );
        },
        'CurrentPageReport': (options: any) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            )
        }
    };

    const onImageHide = () => {
        setDisplayImage(false);
    }

    const itemTemplate = (item: any) => {

        return <img src={`${process.env.REACT_APP_API_URL}/api${item.image}`} style={{ width: '100%', display: 'block' }} />
    }

    const thumbnailTemplate = (item: any) => {
        return <img src={`${process.env.REACT_APP_API_URL}/api${item.thumbnail}`} style={{ width: '100%', display: 'block' }} />
    }

    const exportCSV = (selectionOnly: any) => {
        if (dt) { dt.current.exportCSV({ selectionOnly }) };
    }

    // const exportPdf = () => {
    //     // import('jspdf').then(jsPDF => {
    //     //     import('jspdf-autotable').then(() => {
    //     //         const doc = new jsPDF.default(0, 0);
    //     //         doc.autoTable(exportColumns, products);
    //     //         doc.save('products.pdf');
    //     //     })
    //     // })
    // }

    const exportPdf = ()=> {
        let fileName = t(_title).replace(" ", "_") + "_";
        var doc = new jsPDF.jsPDF('landscape');
        // var doc = new jsPDF({
        //   orientation: "landscape"
        // });
        doc.autoTable(exportColumnsData(), prepareExcelData());
        doc.save(fileName +  '.pdf');
      }

      const exportColumnsData = () => {
        var data:any[] = [];
    
        cols.forEach((column:any) => {
    
          data.push({ title: t("common." + column.headerText), dataKey: t("common." + column.headerText) });
        })
        return data;
      }

    const prepareExcelData = () => {
        let eData: any[] = [];
        rows.forEach(row => {
            let obj: any = {};
            cols.forEach((column: any) => {

                if (column.colType === 1 && column.colTranslate === 0) {
                    if (row[column.colName] === undefined || row[column.colName] === null) {
                        obj[t("common." + column.headerText)] = "";
                    }
                    else {
                        obj[t("common." + column.headerText)] = row[column.colName];
                    }
                }
                else if (column.colType === 1 && column.colTranslate === 1) {
                    if (row[column.colName] === undefined || row[column.colName] === null) {
                        obj[t("common." + column.headerText)] = "";
                    }
                    else {
                        obj[t("common." + column.headerText)] = t(row[column.colName]);
                    }
                }
                else if (column.colType === 2) {
                    if (row[column.colName] === undefined || row[column.colName] === null) {
                        obj[t("common." + column.headerText)] = "";
                    }
                    else {
                        obj[t("common." + column.headerText)] = (row[column.colName]).toString();
                    }
                }
                else if (column.colType === 3) {
                    if (row[column.colName] === undefined || row[column.colName] === null) {
                        obj[t("common." + column.headerText)] = "";
                    }
                    else {
                        obj[t("common." + column.headerText)] = (row[column.colName]).toString();
                    }
                }
            })
            eData.push(obj);
        });

        return eData;
    }
    const exportExcel = () => {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(prepareExcelData());
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'artwork');
        });
    }

    const saveAsExcelFile = (buffer: any, fileName: any) => {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }

    return (
        <div>
            {/* <Galleria ref={galleria1} fullScreen showItemNavigators  value={imageList} responsiveOptions={responsiveOptions} numVisible={5} style={{ maxWidth: '640px' }}
                    activeIndex={activeIndex} onItemChange={(e) => setActiveIndex(e.index)}         item={itemTemplate} thumbnail={thumbnailTemplate} /> */}
            {renderif(allowActions)(
                <Row style={{ marginBottom: 5 }}>
                    <Col lg="3">
                        <h5>{_title}</h5>
                    </Col>

                    <Col lg="9" style={{ textAlign: 'right' }}>
                        <Button label={t('button.new')} icon="pi pi-plus" className="p-button-sm mr-1" onClick={() => onNew()} />
                        <Button label={t('button.edit')} icon="pi pi-pencil" className="p-button-sm mr-1" onClick={() => onEdit()} />
                        <Button label={t('button.delete')} icon="pi pi-trash" className="p-button-sm p-button-danger mr-1" onClick={() => onDelete()} />
                        <Button type="button" icon="pi pi-file-o" onClick={() => exportCSV(false)} className="mr-1" data-pr-tooltip="CSV" />
                        <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success mr-1" data-pr-tooltip="XLS" />
                        <Button type="button" icon="pi pi-file-pdf" onClick={exportPdf} className="p-button-warning p-mr-2" data-pr-tooltip="PDF" />
                    </Col>

                </Row>
            )}
            <div className="card">
                <DataTable
                    ref={dt}
                    dataKey="id"
                    value={rows}
                    selection={selectedRows}
                    selectionMode="checkbox"
                    onSelectionChange={e => setSelectedRows(e.value)}
                    paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    rowsPerPageOptions={[10, 20, 50, 100, 500, 1000]}
                    scrollHeight="440px"
                    scrollable
                    first={first1} rows={rows1} onPage={onCustomPage1} emptyMessage={t("common.No_record_found")}>
                    <Column selectionMode="multiple" headerStyle={{ width: '30px' }}></Column>
                    {dynamicColumns}

                </DataTable>



                <Dialog contentStyle={{ padding: 0 }} header='' visible={displayImag} style={{ width: '530px' }} onHide={() => onImageHide()}>
                    <ImageViewer onHide={onImageHide} imageList={imageList}></ImageViewer>
                </Dialog>
            </div>

        </div>
    );
});