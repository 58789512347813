import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import '../assets/css/style.css';
import { LoginContext } from '../auth';
import { ServiceEndPoint } from '../constants';
import { CommonDataService } from '../services';
import toast, { Toaster } from 'react-hot-toast';
import { confirmDialog } from 'primereact/confirmdialog';

export const Account = () => {
  const { state } = useContext(LoginContext);
  const { t } = useTranslation();
  const [file, setFile] = useState<any>();
  const [org, setOrg] = useState<any>({});

  const commonDataService = new CommonDataService();

  const setProperty = (property: any, value: any) => {
    setOrg({
      ...org,
      [property]: value
    });
  };

  const getOrgnisation = () => {

    commonDataService.executeApiCall(ServiceEndPoint.ORGANISATION.FETCH, { orgId: state.orgID }).then((res: any) => {
      if (res.status) {
        setOrg(res.data);
      }
    });

  }

  const saveChanges = () =>{
    confirmDialog({
      message: t('alert.save_message'),
      header: t('alert.confirmation'),
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-primary',
      acceptLabel: t('button.yes'),
      rejectLabel: t('button.no'),
      accept: ()=>{
        org.updatedBy = state.userId;
          commonDataService.executeApiCall(ServiceEndPoint.ORGANISATION.SAVE, org)
          .then(res=>{
              if(res.status){
                  toast.success(t('alert.record_deleted'),{position:'top-right', duration:3000});
                  
              }
              else{
                  toast.error(t('alert.error'),{position:'top-right', duration:3000});
              }
          });
      }
  });
  }

  useEffect(() => {
    getOrgnisation();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  const handleFile = async (event: any) => {
    const base64 = await convertBase64(event.target.files[0]);
    org.logo = base64;
    setFile(base64);

  }

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  return (
    <Container className='mt-5' >

      <Row>
        <Col lg={2}></Col>
        <Col lg={8}>
          <Row style={{ marginBottom: 5 }}>
            <Col lg={3}>
              <h5>{t('common.account')}</h5>
            </Col>
            <Col lg={9} style={{ textAlign: 'right' }}>
              <Button label={t('button.save_changes')} icon="pi pi-save" className="p-button-sm mr-1" onClick={() => saveChanges()} />

            </Col>
          </Row>
          <div className="card p-10" style={{ padding: 10 }} >
            <Row>
              <Col lg={9}>
                <p>{t('common.org_no')}<span className="required">*</span></p>
                <InputText value={org.organisationNo} aria-describedby="product-group" onChange={(e) => setProperty("organisationNo", e.target.value)} style={{ width: '200px' }} className="" />

                <p>{t('common.org_name')}<span className="required">*</span></p>
                <InputText value={org.orgName} aria-describedby="product-group" onChange={(e) => setProperty("orgName", e.target.value)} style={{ width: '100%' }} className="" />

                <p>{t('common.street_address')}</p>
                <InputText value={org.streetAddress} aria-describedby="product-group" onChange={(e) => setProperty("streetAddress", e.target.value)} style={{ width: '100%' }} className="" />

                <p>{t('common.post_address')}<span className="required">*</span></p>
                <InputText value={org.postAddress} aria-describedby="product-group" onChange={(e) => setProperty("postAddress", e.target.value)} style={{ width: '100%' }} className="" />
                <Row>
                  <Col lg={4}>
                    <p>{t('common.email')}<span className="required">*</span></p>
                    <InputText value={org.email} aria-describedby="product-group" onChange={(e) => setProperty("email", e.target.value)} style={{ width: '100%' }} className="" />
                  </Col>
                  <Col lg={4}>
                    <p>{t('common.phone_number')}<span className="required">*</span></p>
                    <InputText value={org.phoneNumber} aria-describedby="product-group" onChange={(e) => setProperty("phoneNumber", e.target.value)} style={{ width: '100%' }} className="" />
                  </Col>
                  <Col lg={4}>
                    <p>{t('common.domain_name')}<span className="required">*</span></p>
                    <InputText value={org.email_domain} aria-describedby="product-group" onChange={(e) => setProperty("email_domain", e.target.value)} style={{ width: '100%' }} className="" />
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <p>{t('common.country')}<span className="required">*</span></p>
                    <InputText value={org.country} aria-describedby="product-group" onChange={(e) => setProperty("country", e.target.value)} style={{ width: '100%' }} className="" />
                  </Col>
                  <Col lg={4}>
                    <p>{t('common.department')}<span className="required">*</span></p>
                    <InputText value={org.department} aria-describedby="product-group" onChange={(e) => setProperty("department", e.target.value)} style={{ width: '100%' }} className="" />
                  </Col>
                  <Col lg={4}>
                    <p>{t('common.postal_code')}<span className="required">*</span></p>
                    <InputText value={org.postalCode} aria-describedby="product-group" onChange={(e) => setProperty("postalCode", e.target.value)} style={{ width: '100%' }} className="" />
                  </Col>
                </Row>
              </Col>
              <Col lg={3} style={{ textAlign: 'center' }}>
                <img src={org.logo} style={{ width: 150, height: 150, marginBottom: 5 }} />
                <div className="inputWrapper">
                  <input className="fileInput" type="file" name="file1" onChange={handleFile} />
                  Browse
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col lg={2}></Col>
      </Row>
      <Toaster />
    </Container>
  );
};
