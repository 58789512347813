import React, { useContext, useState } from 'react';
import { Col, Container, Dropdown, Row, Image, Button } from 'react-bootstrap';
import { LoginContext, LoginAction, ChangePassword } from '../../auth';
import { ToggleLocale } from './toggle-locale';
import './header.css';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Menubar } from 'primereact/menubar';
import logo from '../../assets/images/logo_white.png';
import { Dialog } from 'primereact/dialog';
import 'font-awesome/css/font-awesome.min.css';
import renderif from '../../constants/renderif';


export const Header = () => {
  const loginContext = useContext(LoginContext);
  const { t } = useTranslation();
  const [displayBasic, setDisplayBasic] = useState(false);
  const logout: LoginAction = {
    type: 'logout',
  };

  const handleLogoutSubmit = (e: any) => {
    loginContext.dispatch(logout);
  };

  const onHide = () => {
    setDisplayBasic(false);
  }

  const items = [
    {
      label: t('menu.dashboard'),
      icon: 'fa fa-pie-chart fa-3',
      url: '/'
    },
    {
      label: t('menu.artwork'),
      icon: 'pi pi-fw pi-images',
      url: '/art-work'
    },
    {
      label: t('menu.transfer_request'),
      icon: 'fa fa-sliders fa-3',
      url: '/art-work-request'
    },
    {
      label: t('menu.artwork_receive'),
      icon: 'fa fa-sliders fa-3',
      url: '/art-work-receive'
    },
    {
      label: t('menu.mastersetup.title'),
      icon: 'pi pi-fw pi-cog',
      items: [
        // {
        //   label: t('menu.mastersetup.product_group'),
        //   icon: 'fa fa-th-large',
        //   url: '/product-group'
        // },
        {
          label: t('menu.mastersetup.product_category'),
          icon: 'fa fa-file',
          url: '/product-category'
        },
        {
          label: t('menu.mastersetup.technique'),
          icon: 'fa fa-cubes',
          url: '/technique'
        },
        {
          label: t('menu.mastersetup.material'),
          icon: 'fa fa-recycle',
          url: '/material'
        },
        {
          label: t('menu.mastersetup.artist'),
          icon: 'fa fa-user',
          url: '/artist'
        },
        {
          label: t('menu.mastersetup.prefered-location'),
          icon: 'fa fa-recycle',
          url: '/prefered-location'
        },
        {
          label: t('menu.mastersetup.location.title'),
          icon: 'fa fa-map-marker',

          items: [
            {
              label: t('menu.mastersetup.location.area'),
              icon: 'fa fa-location-arrow',
              url: '/area'
            },
            {
              label: t('menu.mastersetup.location.room'),
              icon: 'fa fa-building-o fa-2',
              url: '/area-room'
            }
          ]
        },
        {
          label: t('menu.mastersetup.status'),
          icon: 'fa fa-question-circle-o',
          url: '/status'
        },

      ]
    },
    // {
    //   label: t('menu.users'),
    //   icon: 'pi pi-fw pi-users',

    // },
    {
      label: t('menu.account'),
      icon: 'fa fa-user-o',
      url: '/account'
    },
    {
      label: t('menu.report'),
      icon: 'pi pi-fw pi-file-pdf',
      url: '/report'
    }
  ];

  const itemsRece = [
    {
      label: t('menu.artwork_receive'),
      icon: 'fa fa-sliders fa-3',
      url: '/art-work-receive'
    }
  ];

  const itemEmployee = [
    {
      label: t('menu.artwork-request'),
      icon: 'fa fa-sliders fa-3',
      url: '/'
    }
  ];

  const start = <div><Image alt="logo" src={logo} className="p-mr-2" style={{ width: 50 }}></Image><span style={{ color: '#fff', marginLeft: 10, fontSize: 20, marginTop: 10 }}>Art Work</span></div>;
  const end = <Row className='m-0 p-0'>



    <Col xs={6} md={6} className='text-right setting-icon m-0 p-0 mr-3'>
      <ToggleLocale isLoggedIn={true} />
    </Col>
    <Col xs={1} md={1} className='setting-icon'>
      <Dropdown>
        <Dropdown.Toggle
          variant='link'
          className='settings-button m-0 p-0 mr-1'
        >

          {/* <i className='fas fa-user-circle' style={{ color: '#fff' }}></i> */}

          <p><span style={{ fontSize: 16, color: '#fff' }}>{loginContext.state.name}&nbsp;<i className='fas fa-user-circle' style={{ color: '#fff' }}></i> </span></p>
        </Dropdown.Toggle>

        <Dropdown.Menu className='dropdown-menu'>
          <div  onClick={()=>setDisplayBasic(true)}>
            <Col>{t('button.change_password')}</Col>
          </div>
          <NavLink to='/' onClick={handleLogoutSubmit}>
            <Col>{t('button.logout')}</Col>
          </NavLink>

        </Dropdown.Menu>
      </Dropdown>
    </Col>
  </Row>;


  return (

    <LoginContext.Consumer>
      {(loginValues) => (
        <>
          <Container fluid className='header' style={{ padding: 0 }}>
            <div>
              <div className="card" style={{ borderWidth: 0 }}>
                <Menubar start={start} end={end} style={{ backgroundColor: '#0061AE', borderWidth: 0, borderRadius: 0 }} />
                {renderif(loginValues.state.userType === "1")(
                  <Menubar model={items} style={{ borderWidth: 0, borderRadius: 0, borderBottomWidth: 1, backgroundColor: '#F5F5F5', color: '#fff', fontSize: 12, padding: 3 }} />
                )}
                {renderif(loginValues.state.userType === "3")(
                  <Menubar model={itemsRece} style={{ borderWidth: 0, borderRadius: 0, borderBottomWidth: 1, backgroundColor: '#F5F5F5', color: '#fff', fontSize: 12, padding: 3 }} />
                )}
                {renderif(loginValues.state.userType === "2")(
                  <Menubar model={itemEmployee} style={{ borderWidth: 0, borderRadius: 0, borderBottomWidth: 1, backgroundColor: '#F5F5F5', color: '#fff', fontSize: 12, padding: 3 }} />
                )}
              </div>
            </div>
            <Dialog header={t('button.change_password')} visible={displayBasic} style={{ width: '450px' }} onHide={() => onHide()}>
              <ChangePassword onHide={onHide}></ChangePassword>
            </Dialog>
          </Container>
        </>
      )}
    </LoginContext.Consumer>
  );
};
