import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import './dashboard.css';
import { LoginContext } from '../auth';
import { ServiceEndPoint } from '../constants';
import { CommonDataService } from '../services';
import toast, { Toaster } from 'react-hot-toast';
import { confirmDialog } from 'primereact/confirmdialog';
import ReactECharts from 'echarts-for-react';
import { Panel } from 'primereact/panel';

export const Dashboard = () => {
  const { state } = useContext(LoginContext);
  const { t } = useTranslation();
  const [file, setFile] = useState<any>();
  const [org, setOrg] = useState<any>({});
  const [dashboardStatus, setDashboardStatus] = useState<any>({});
  const [dashboardArtistStatus, setDashboardArtistStatus] = useState<any>({});

  const commonDataService = new CommonDataService();

  const setProperty = (property: any, value: any) => {
    setOrg({
      ...org,
      [property]: value
    });
  };

  const getOrgnisation = () => {

    commonDataService.executeApiCall(ServiceEndPoint.ORGANISATION.FETCH, { orgId: state.orgID }).then((res: any) => {
      if (res.status) {
        setOrg(res.data);
      }
    });

  }

 const getDashboardStatus = () =>{
  commonDataService.executeApiCall(ServiceEndPoint.DASHBOARD.STOCK_STATUS, { orgId: state.orgID }).then((res: any) => {
    if (res.status && res.data.length > 0) {
      setDashboardStatus(res.data[0]);
      
    }
  });
 }
 const getDashboardArtistStatus = () =>{
  commonDataService.executeApiCall(ServiceEndPoint.DASHBOARD.ARTIST_STATUS, { orgId: state.orgID }).then((res: any) => {
    if (res.status && res.data.length > 0) {
      setDashboardArtistStatus(res.data);
      
    }
  });
 }

  useEffect(() => {
    getDashboardStatus();
    getDashboardArtistStatus();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  const getOption = () => {
    let labels: any[] = [];
    let _data: any[] = [];
    if(dashboardArtistStatus.length > 0){
    dashboardArtistStatus.forEach((element: any) => {
      labels.push(element.name);
      _data.push(element.total);
    });
  }
    return {
      grid:{
        top:20,
        left:30,
        right: 30,
        bottom:100
      },
      xAxis: {
        type: 'category',
        data: labels,
        axisLabel: {
          interval: 0,
          rotate: -30 //If the label names are too long you can manage this by rotating the label.
        }
      },
      yAxis: {
        type: 'value'
      },
      series: [{
        data: _data,
        type: 'bar',
        showBackground: true,
        backgroundStyle: {
          color: 'rgba(180, 180, 180, 0.2)'
        }
      }]
    };
  }
  const getStatusChartOption = () => {
    return {
      legend: {
          top: 'bottom'
      },
      tooltip: {
        trigger: 'item'
    },
    
      toolbox: {
          show: true,
          feature: {
              mark: {show: true},
              dataView: {show: false, readOnly: false},
              restore: {show: false},
              saveAsImage: {show: true}
          }
      },
      series: [
          {
              type: 'pie',
              radius: '50%',
              label: {
                show: true,
                formatter: '{c}  {name|{b}}',
                fontSize: 16,
                rich: {
                    name: {
                    }
                },
              },
              data: [
                  // {value: dashboardStatus.total, name: t('common.total')},
                  {value: dashboardStatus.saknas, name: t('common.missing')},
                  {value: dashboardStatus.hanged, name: t('common.hanged')},
                  {value: dashboardStatus.under_reparation, name: t('common.in_repair')},
                  {value: dashboardStatus.i_lager, name: t('common.inStock')}
              ]
          }
      ],
      emphasis: {
        itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
    }
  };
  }

  return (
    <Container className='' >
      <div className="MuiGrid-root MuiGrid-container row">
        <div className="MuiGrid-root MuiGrid-item  MuiGrid-grid-xl-2">
          <div className="MuiPaper-root MuiCard-root jss99 MuiPaper-elevation0 MuiPaper-rounded">
            <div className="MuiCardContent-root jss100">
              <div className="MuiBox-root jss110 MuiGrid-root MuiGrid-container">
                <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-auto">
                  <h5 className="MuiTypography-root MuiBox-root jss111 jss101 MuiTypography-h5">{t('common.total_artwork')}</h5>
                  <h2 className="MuiTypography-root MuiBox-root jss112 MuiTypography-h2">{dashboardStatus.total}</h2>
                </div>
                <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-auto">

                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="MuiGrid-root MuiGrid-item  MuiGrid-grid-xl-2">
          <div className="MuiPaper-root MuiCard-root jss99 MuiPaper-elevation0 MuiPaper-rounded">
            <div className="MuiCardContent-root jss100">
              <div className="MuiBox-root jss110 MuiGrid-root MuiGrid-container">
                <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-auto">
                  <h5 className="MuiTypography-root MuiBox-root jss111 jss101 MuiTypography-h5">{t('common.hanged')}</h5>
                  <h2 className="MuiTypography-root MuiBox-root jss112 MuiTypography-h2">{dashboardStatus.hanged}</h2>
                </div>
                <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-auto">

                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="MuiGrid-root MuiGrid-item  MuiGrid-grid-xl-2">
          <div className="MuiPaper-root MuiCard-root jss99 MuiPaper-elevation0 MuiPaper-rounded">
            <div className="MuiCardContent-root jss100">
              <div className="MuiBox-root jss110 MuiGrid-root MuiGrid-container">
                <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-auto">
                  <h5 className="MuiTypography-root MuiBox-root jss111 jss101 MuiTypography-h5">{t('common.missing')}</h5>
                  <h2 className="MuiTypography-root MuiBox-root jss112 MuiTypography-h2">{dashboardStatus.saknas}</h2>
                </div>
                <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-auto">

                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="MuiGrid-root MuiGrid-item  MuiGrid-grid-xl-2">
          <div className="MuiPaper-root MuiCard-root jss99 MuiPaper-elevation0 MuiPaper-rounded">
            <div className="MuiCardContent-root jss100">
              <div className="MuiBox-root jss110 MuiGrid-root MuiGrid-container">
                <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-auto">
                  <h5 className="MuiTypography-root MuiBox-root jss111 jss101 MuiTypography-h5">{t('common.in_repair')}</h5>
                  <h2 className="MuiTypography-root MuiBox-root jss112 MuiTypography-h2">{dashboardStatus.under_reparation}</h2>
                </div>
                <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-auto">

                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="MuiGrid-root MuiGrid-item  MuiGrid-grid-xl-2">
          <div className="MuiPaper-root MuiCard-root jss99 MuiPaper-elevation0 MuiPaper-rounded">
            <div className="MuiCardContent-root jss100">
              <div className="MuiBox-root jss110 MuiGrid-root MuiGrid-container">
                <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-auto">
                  <h5 className="MuiTypography-root MuiBox-root jss111 jss101 MuiTypography-h5">{t('common.inStock')}</h5>
                  <h2 className="MuiTypography-root MuiBox-root jss112 MuiTypography-h2">{dashboardStatus.i_lager}</h2>
                </div>
                <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-auto">

                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
      <Row>
        <Col lg={8}>
        <Panel header={t('common.artist_wise_stock')}>
          <ReactECharts
            option={getOption()}

          />
          </Panel>
        </Col>
        <Col lg={4}>
        <Panel header={t('common.status_wise_stock')}>
        <ReactECharts
            option={getStatusChartOption()}

            
          />
          </Panel>
        </Col>
      </Row>
    </Container>
  );
};
