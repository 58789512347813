import { LoginState, LoginAction } from '../types';

export const loginReducer = (
  state: LoginState,
  action: LoginAction
): LoginState => {
  debugger;
  switch (action.type) {
    case 'login':
      //set user-token to session storage
      localStorage.removeItem('context');
      localStorage.setItem('context', JSON.stringify(action.payload));
      let _state = action.payload;
      
      return {
        ..._state,
        loggedIn: true,
        userId: action.payload.userId,
        token: action.payload.token
      };
    case 'logout':
      //clear sessionStorage
      var lang = localStorage.getItem("lang");
      localStorage.clear();
      if(lang)
            localStorage.setItem("lang",lang);
      return {
        ...state,
        loggedIn: false,
        userId: '',
        token: null
      };
    default:
      throw new Error();
  }
};
