import React, { useContext, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LoginContext } from '../auth';
import { ServiceEndPoint } from '../constants';
import { Button } from 'primereact/button';
import toast, { Toaster } from 'react-hot-toast';
import { Calendar } from 'primereact/calendar';
//import { v4 as uuidv4 } from 'uuid';
import { InputTextarea } from 'primereact/inputtextarea';
import '../assets/css/style.css';
import { CommonDataService } from '../services';
import renderif from '../constants/renderif';
import { Dropdown } from 'primereact/dropdown';
import Moment from 'moment';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
interface RequestProps {
    stockId: string;
    onHide: () => void;
    onSuccess: () => void;
}

export const ReceiveRequest = (props: RequestProps) => {
    const { state } = useContext(LoginContext);
    const [id, setId] = useState(props.stockId);
    const [receiveddate, setReceivedDate] = useState<any>();
    const [reason, setReason] = useState('');
    const [area, setArea] = useState(-1);
    const [room, setRoom] = useState(-1);
    const [areaList, setAreaList] = useState<any[]>([]);
    const [roomList, setRoomList] = useState<any[]>([]);
    const [bChecked, setBChecked] = useState(true);
    const [value, setValue] = useState('');
    const { t } = useTranslation();
    const commonDataService = new CommonDataService();

    useEffect(() => {
        getAreaList();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const save = (statusId: number) => {
        commonDataService.executeApiCall(ServiceEndPoint.ART_WORK.RECEIVE_STOCK_REQUEST,
            {
                orgId: state.orgID,
                userId: state.userId,
                status: statusId,
                bSelf: bChecked,
                rpName: value,
                stockId: "'" + props.stockId.replace(",", "','") + "'"
            })
            .then(res => {
                console.log(res);
                if (res.status) {
                    if (res.data > 0) {
                        props.onSuccess();
                    }
                    else if (res.data === -1) {
                        toast.error(t('alert.duplicate_record'), { position: 'top-right', duration: 3000 });
                    }
                }
            });
    }

    const onAreaChange = (e: any) => {
        setArea(e.value);
        getRoomList(e.value);
    }
    const getAreaList = () => {
        commonDataService.executeApiCall(ServiceEndPoint.AREA.FETCH, { orgId: state.orgID }).then(res => {
            if (res.status) {
                setAreaList([]);
                let rData: any[] = [];
                res.data.forEach((item: any) => {
                    rData.push(item);
                });
                setAreaList(rData);
            }
        });
    }
    const onRoomChange = (e: any) => {
        setRoom(e.value);
    }
    const getRoomList = (areaId: number) => {
        commonDataService.executeApiCall(ServiceEndPoint.AREA_ROOM.FETCH, { orgId: state.orgID }).then(res => {
            if (res.status) {
                setRoomList([]);
                let rData: any[] = [];
                res.data.forEach((item: any) => {
                    if (item.areaId === areaId) {
                        rData.push(item);
                    }
                });
                setRoomList(rData);
            }
        });
    }



    return (
        <div style={{ marginTop: 10 }}>
           
                <div>
                    <Checkbox inputId="cb1" value={0} onChange={(e)=> setBChecked(e.checked)} checked={bChecked} />
                    <label htmlFor="cb1" className="p-checkbox-label" style={{position:'relative', top: 7, left: 10, fontSize:13}}>{t('common.self')}</label>

                    <p>{t('common.representative_name')}</p>
                    <InputText disabled={bChecked} value={value} aria-describedby="status-name" onChange={(e) => setValue(e.target.value)} style={{ width: '100%' }}className=""/>

                    <div style={{ width: '100%', marginTop: 15, textAlign: 'right' }}>
                        <Button label={t('button.cancel')} onClick={() => props.onHide()} className="p-button-text" />
                        <Button label={t('button.hand_over')} onClick={() => save(4000)} autoFocus />
                    </div>
                    <Toaster />
                </div>
           
        </div>
    );
}