import React, { useContext, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LoginContext } from '../auth';
import { AppDataTable } from '../common/components/table';
import { ServiceEndPoint } from '../constants';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { confirmDialog } from 'primereact/confirmdialog';
import '../assets/css/style.css';
import { ActionType, FORM } from '../constants/enum';
import toast, { Toaster } from 'react-hot-toast';
import { CommonDataService } from '../services';
import renderif from '../constants/renderif';
import { ArtWork } from './art-work';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { ReceiveRequest } from './receive-request';

import { TabView, TabPanel } from 'primereact/tabview';

export const ArtWorkReceiveList = () => {
    const { state } = useContext(LoginContext);
    const ref = useRef<any>(null);
    const ref1 = useRef<any>(null);
    const { t } = useTranslation();
    const [payload, setPayLoad] = useState(JSON.stringify({ orgId: state.orgID }));
    const [displayBasic, setDisplayBasic] = useState(false);
    const [selectedObject, setSelectedObject] = useState('');
    const [isForm, setIsForm] = useState(false);

    const commonDataService = new CommonDataService();
    const onAction = (actionType: ActionType, selectedRows: any[]) => {
        switch (actionType) {
            case ActionType.ADD:
                addNew();
                break;
            case ActionType.EDIT:
                //edit(selectedRows);
                break;
            case ActionType.DELETE:
                deleteConfirm(selectedRows);
                break;
            default:
                break;
        }

    }

    const addNew = () => {

        setIsForm(true);
    }

    const edit = () => {
        let selectedRows = ref.current.getSelectedRows();
        if (selectedRows === undefined || selectedRows.length === 0) {
            confirmAlert({
                title: t('common.message'),
                message: t('alert.select_at_least_one_record'),
                buttons: [
                    {
                        label: t('button.ok'),
                        onClick: () => { }
                    }
                ]
            });
        }

        else {
            setSelectedObject(ref.current.getSelectedRows().map((item: any) => item.id).join(","));
            setDisplayBasic(true);
        }
    }

    const onHide = () => {
        setDisplayBasic(false);
    }

    const onSuccess = () => {
        setDisplayBasic(false);
        toast.success(t('alert.successfully_saved'), { position: 'top-right', duration: 3000 });
        ref.current.updateMetaData();

        setPayLoad(JSON.stringify({ orgId: state.orgID }));
    }

    const deleteConfirm = (selectedRows: any[]) => {
        confirmDialog({
            message: t('alert.delete_message'),
            header: t('alert.delete_confirmation'),
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: t('button.yes'),
            rejectLabel: t('button.no'),
            accept: () => {
                commonDataService.executeApiCall(ServiceEndPoint.PRODUCT_CATEGORY.DELETE, { ids: selectedRows.map(item => item.id).join(), userId: state.userId })
                    .then(res => {
                        console.log(res);
                        if (res.status) {
                            toast.success(t('alert.record_deleted'), { position: 'top-right', duration: 3000 });
                            ref.current.updateMetaData();
                        }
                        else {
                            toast.error(t('alert.error'), { position: 'top-right', duration: 3000 });
                        }
                    });
            }
        });
    };


    return (
        <Container>
            <TabView>
                <TabPanel header="Pending">
                    {renderif(isForm === false)(
                        <Row style={{ marginBottom: 5 }}>
                            <Col lg="3">
                                <h5>{t('menu.transfer_request')}</h5>
                            </Col>

                            <Col lg="9" style={{ textAlign: 'right' }}>
                                <Button label={t('button.hand_over')} icon="pi pi-id-card" className="p-button-sm mr-1" onClick={() => edit()} />

                            </Col>

                        </Row>
                    )}
                    {renderif(isForm === false)(
                        <AppDataTable
                            ref={ref}
                            title={t('menu.artwork')}
                            formId={FORM.ART_WORK_RECEIVE}
                            endpointUrl={ServiceEndPoint.ART_WORK.FETCH_USER_REQUEST_RECEIVE}
                            payload={payload}
                            bAllowActions={false}
                            onAction={onAction} />
                    )}
                    <Dialog header={t('common.approve_request')} visible={displayBasic} style={{ width: '30vw' }} onHide={() => onHide()}>
                        <ReceiveRequest onHide={onHide} onSuccess={onSuccess} stockId={selectedObject}></ReceiveRequest>
                    </Dialog>
                    {/* <Dialog header={t('menu.mastersetup.product_category')} visible={displayBasic} style={{ width: '30vw' }}  onHide={() => onHide()}>
                    <ProductCategory onHide={onHide} onSuccess={onSuccess} groupId={selectedObject.groupId} id={selectedObject.id} name={selectedObject.name}></ProductCategory>
                </Dialog> */}
                    <Toaster />
                </TabPanel>
                <TabPanel header="Handed Over">
                {/* <Row style={{ marginBottom: 5 }}>
                            <Col lg="3">
                                <h5>{t('menu.transfer_request')}</h5>
                            </Col>

                            <Col lg="9" style={{ textAlign: 'right' }}>
                                <Button label={t('button.refresh')} icon="pi pi-refresh" className="p-button-sm mr-1" onClick={() => edit()} />

                            </Col>

                        </Row> */}
                <AppDataTable
                            ref={ref1}
                            title={t('menu.artwork')}
                            formId={FORM.ART_WORK_RECEIVE}
                            endpointUrl={ServiceEndPoint.ART_WORK.FETCH_USER_REQUEST_RECEIVE_HAND_OVER}
                            payload={payload}
                            bAllowActions={false}
                            onAction={onAction} />
                </TabPanel>

            </TabView>

        </Container>

    );
}