import React, { useContext, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { LoginContext } from '../../auth';
import { ServiceEndPoint } from '../../constants';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import toast, { Toaster } from 'react-hot-toast';
import { Checkbox } from 'primereact/checkbox';
import '../../assets/css/style.css';
import { CommonDataService } from '../../services';

interface StatusProps {
    statusId: number;
    statusName: string;
    allowTransfer: number;
    onHide: () => void;
    onSuccess: () => void;
}

export const ProductStatus = (props: StatusProps) => {
    const { state } = useContext(LoginContext);
    const [id, setId] = useState(props.statusId);
    const [value, setValue] = useState(props.statusName);
    const [bChecked, setBChecked] = useState(props.allowTransfer);

    const { t } = useTranslation();
    const commonDataService = new CommonDataService();
   
    const save = ()=>{
        commonDataService.executeApiCall(ServiceEndPoint.STATUS.SAVE, 
            {
                orgId: state.orgID,
                userId: state.userId,
                statusId: id,
                statusName: value,
                allowTransfer: bChecked
            })
        .then(res=>{
            console.log(res);
            if(res.status){
                if(res.data > 0){
                    props.onSuccess();
                }
                else if(res.data === -1){
                    toast.error(t('alert.duplicate_record'),{position:'top-right', duration:3000});
                }
            }
        });
    }

   

    return (
        <div style={{marginTop:10}}>
            <p>{t('common.status')}<span className="required">*</span></p>
            <InputText value={value} aria-describedby="status-name" onChange={(e) => setValue(e.target.value)} style={{ width: '100%' }}className=""/>
            <Checkbox inputId="cb1" value={0} onChange={(e)=> setBChecked(e.checked?1:0)} checked={bChecked === 1} />
            <label htmlFor="cb1" className="p-checkbox-label" style={{position:'relative', top: 7, left: 10, fontSize:13}}>{t('common.allow_transfer')}</label>
            
            <div style={{width: '100%', marginTop: 15, textAlign:'right'}}>
                <Button label={t('button.cancel')} onClick={() => props.onHide()} className="p-button-text" />
                <Button label={t('button.submit')} onClick={() => save()} autoFocus />
            </div>
            <Toaster />
        </div>
    );
}