export const ServiceEndPoint = {
    USER: {
        SEND_OTP: '/user/send-otp'
    },
    DASHBOARD:{
        STOCK_STATUS:'/dashboard/dashboard-stock-status',
        ARTIST_STATUS:'/dashboard/dashboard-artist-status'
    },
    ORGANISATION:{
        FETCH:'/organisation/fetch',
        SAVE:'/organisation/save',
        FETCH_LIST:'/organisation/fetch-organisation-list'
    },
    FORM_META_DATA:{
        FETCH:'/form-meta-data/fetch-meta'
    },
    PRODUCT_GROUP: {
        FETCH: '/product-group/fetch-product-groups',
        SAVE_PRODUCT_GROUP: '/product-group/save-product-group',
        DELETE_PRODUCT_GROUP: '/product-group/delete-product-group'
    },
    PRODUCT_CATEGORY: {
        FETCH: '/product-category/fetch',
        SAVE: '/product-category/save',
        DELETE: '/product-category/delete'
    },
    ARTIST: {
        FETCH: '/artist/fetch',
        SAVE: '/artist/save',
        DELETE: '/artist/delete'
    },
    TECHNIQUE: {
        FETCH: '/technique/fetch',
        SAVE: '/technique/save',
        DELETE: '/technique/delete'
    },
    MATERIAL: {
        FETCH: '/material/fetch',
        SAVE: '/material/save',
        DELETE: '/material/delete'
    },
    AREA: {
        FETCH: '/area/fetch',
        SAVE: '/area/save',
        DELETE: '/area/delete'
    },
    AREA_ROOM: {
        FETCH: '/area-room/fetch',
        FETCH_BY_AREA: '/area-room/fetch-by-area',
        SAVE: '/area-room/save',
        DELETE: '/area-room/delete'
    },
    STATUS: {
        FETCH: '/status/fetch-status-list',
        SAVE: '/status/save-status',
        DELETE: '/status/delete-status'
    },
    PREFERED_LOCATION: {
        FETCH: '/prefered-location/fetch',
        SAVE: '/prefered-location/save',
        DELETE: '/prefered-location/delete'
    },
    ART_WORK: {
        FETCH: '/art-work/fetch',
        SAVE: '/art-work/save',
        DELETE: '/art-work/delete',
        FETCH_IMAGES: '/art-work/stock-image-list',
        SAVE_IMAGE: '/art-work/stock-save-image',
        UPDATE_STOCK_REQUEST: '/art-work/update-user-stock-request',
        FETCH_USER_REQUEST_RECEIVE: '/art-work/fetch-user-stocks-for-receive',
        FETCH_USER_REQUEST_RECEIVE_HAND_OVER: '/art-work/fetch-user-stocks-for-receive-hand-over',
        DELETE_IMAGE: '/art-work/delete-stock-image',
        RECEIVE_STOCK_REQUEST: '/art-work/receive-user-stock-request',
        FETCH_USER_REQUEST_ADMIN: '/art-work/fetch-user-stock-request',

        FETCH_FOR_REQUEST: '/art-work-employee/fetch-for-request',
        FETCH_IMAGES_EMPLOYEE: '/art-work-employee/stock-image-list',
        SAVE_REQUEST: '/art-work-employee/save-stock-request',
        FETCH_USER_REQUEST: '/art-work-employee/fetch-user-stock-request',
        FETCH_USER_REQUEST_DETAIL: '/art-work-employee/fetch-user-stock-request-detail',
        DELETE_STOCK_REQUEST:'/art-work-employee/delete-stock-request-detail'
    },
};